import React from "react";
import eventSource from 'customs/connections/EventSource';

var noti_source_cnt = 0;

const helpers = {
    showLoading: function() {
        document.getElementById('loading').classList.add('loading-active');
    },
    hideLoading: function() {
        document.getElementById('loading').classList.remove('loading-active');
    },
    addLineBreaks: function(string) {
        return string.split('<br>').map((text, index) => (
            <React.Fragment key={`${index}`}>
              {text}
              <br />
            </React.Fragment>
          ));
    },
    createNotificationSource: function(id, props) {
        noti_source_cnt++;
        if (noti_source_cnt > 1) {
            noti_source_cnt = 0;
            return null;
        }
        return eventSource('/notification/' + id, props);
    },
    getErrorMessage: function(error) {
        var default_err = 'An error has occurred, please try again.';
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                let is_post_tnx = error.response.config
                    && (error.response.config.url && (error.response.config.url.indexOf("api/txn") > 0 || error.response.config.url.indexOf("api/schedule") > 0))
                    && (error.response.config.method && error.response.config.method.toLowerCase() === 'post');
                const result = error.response.data.errors;
                var arr = [];
                if (result) {
                    Object.keys(result).forEach(function(key, val) {
                        if (result[key] && result[key][0]){
                            let msg = result[key][0]['message'] ? result[key][0]['message'] : result[key][0];
                            if (is_post_tnx && key === 'amount') {
                                let amount = msg.match(/\d+/)[0];
                                msg = msg.replace(amount, '$' + helpers.formatMoney(amount / 100, 2, '.', ','))
                            }
                            else if (key === 'payment_method_id') msg = msg.replace('payment method id', 'card');
                            else if (key === 'recipient_id') msg = msg.replace(' type to index', '');
                            else if (key === 'recur_period_units') msg = msg.replace('recur period units', 'recurring period');
                            else if (msg === 'The token has been blacklisted') msg = '';
                            arr.push(msg);
                        }
                    });
                } else arr.push(default_err);
                default_err = arr.join('<br>');
            } else if (error.response.data.status === 'Token is Invalid' || error.response.data.status === 'Token is Expired'
                || error.response.data.status === 'Authorization Token not found') {
                return false;
            } else if (error.response.data.error) {
                default_err = error.response.data.error;
            } else if (error.response.data.message) {
                default_err = error.response.data.message === 'The token has been blacklisted' ? '' : error.response.data.message;
            }
        }
        return default_err;
    },
    createFormData(formData, data, key) {
        if ( ( typeof data === 'object' && data !== null ) || Array.isArray(data) ) {
            for ( let i in data ) {
                if ( ( typeof data[i] === 'object' && data[i] !== null ) || Array.isArray(data[i]) )
                    formData = helpers.createFormData(formData, data[i], key + '[' + i + ']');
                else
                    if (data[i] !== null && data[i] !== undefined) formData.append(key + '[' + i + ']', data[i]);
            }
        } else
            if (data !== null && data !== undefined) formData.append(key, data);
        return formData;
    },
    formatMoney(number, decPlaces, decSep, thouSep) {
        return parseFloat(''+number).toFixed(decPlaces).replace('.', decSep).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + thouSep);
    }
}

export default helpers;