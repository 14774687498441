import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardDetailsCard from "views/Components/CardDetailsCard.jsx";
import TransactionDetailsCard from "views/Components/TransactionDetailsCard.jsx";
import TransactionDetailsDialog from "views/Dashboard/Dialogs/TransactionDetailsDialog.jsx";
import CardUpdateDialog from "views/Dashboard/Dialogs/CardUpdateDialog.jsx";
import ConfirmDialog from 'customs/components/ConfirmDialog';

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// core components
import Button from "components/CustomButtons/Button.jsx";
import axios from 'axios/axios';
import GridItem from "components/Grid/GridItem.jsx";
import helpers from "customs/helpers/helpers";

class ViewCardDialog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        transactions: {},
        schedules: {},
        transactionDetailsModal: false,
        transactionDetailsModalMsg: null,
        cardUpdateDialogModal: false,
        cardUpdateDialogModalMsg: null,
        confirmModal: false,
        confirmModalTitle: '',
        confirmModalMsg: '',
        confirmAction: '',
        noLabel: "Cancel",
        yesLabel: "Ok",
      };
      this.handleUpdateCardDoNothing = this.handleUpdateCardDoNothing.bind(this);
      this.handleViewCardDoNothing = this.handleViewCardDoNothing.bind(this);
    }

    handleUpdateCardDoNothing(id, alias, is_default) {}
    handleViewCardDoNothing(id) {}

    handleFetchTransaction(id) {
      helpers.showLoading();
      axios(this.props).get("/txn/" + id).then(response => this.processFetchTransaction(response)).catch(error => this.processErrorAxios(error, null, "An error has occurred while retrieving your transaction, please try again."));
    }
  
    processFetchTransaction(response) {
      helpers.hideLoading();
      this.setState({"transactionDetailsModal": true, "transactionDetailsModalMsg": response.data});
    }
  
    processErrorAxios(error, prop, err_msg) {
      helpers.hideLoading();
      let default_err = helpers.getErrorMessage(error);
      let saveDetailsErrors = default_err === false ? err_msg : default_err;
      this.props.handleChildError(saveDetailsErrors, 'danger');
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    showEdit() {
      this.setState({cardUpdateDialogModal: true, cardUpdateDialogModalMsg: this.props.details});
    }

    handleDeleteClick() {
      this.setState({"confirmModal": true, "confirmModalTitle": "Delete Card", confirmModalMsg: "", yesLabel: "Delete", confirmAction: "delete_card"});
    }

    doDeleteCard() {
      helpers.showLoading();
      axios(this.props).get('payment_method/' + this.props.details.id + '/delete')
        .then(response => this.processDeleteCard(response))
        .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
    }

    processDeleteCard() {
      this.props.onClose("viewCardDialogModal");
      this.props.processUpdateCard('', '', 'deleted');
    }

    handleConfirmClick() {
      this.setState({"confirmModal": false, "confirmModalTitle": "", confirmModalMsg: "", yesLabel: "Ok"});
      if (this.state.confirmAction === "delete_card") {
        this.doDeleteCard();
      }
    }

    render() {
        const { classes } = this.props;
        const details = this.props.details;
        const btnStyle = {background: "transparent", width: "100%", padding: "0px", outline: "0", margin: "0px", border: "0"};
        return (
            <Dialog
                style={{zIndex: 10000, right: "-35px", left: "-35px"}}
                classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
                open={this.props.viewCardDialogModal}
                keepMounted
                onClose={()=>this.props.onClose("viewCardDialogModal")}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle style={{paddingBottom:'0px'}} className={classes.modalBodyBody}>
                    {'Giving History'}
                    <i className="fa fa-edit" onClick={() => this.showEdit()} style={{fontSize: '25px', margin: "0px 03px", float: "right"}}/>
                    <i className="fa fa-trash" onClick={() => this.handleDeleteClick()} style={{fontSize: '25px', margin: "0px 15px 0px 3px", float: "right"}}/>
                </DialogTitle>
                <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
                    <CardDetailsCard
                        details={details}
                        onToggleSwitch={this.handleUpdateCardDoNothing}
                        onViewCardClick={this.handleViewCardDoNothing}
                        doShowToggle={false}
                        {...this.props}/>
                    <div style={{minWidth: '300px', width: '100%', textAlign: "left"}}>
                        {
                          details && details.transactions && details.transactions.length > 0
                          ? details.transactions.map((prop, key) => {
                                return (
                                  <GridItem xs={12} sm={12} key={key}>
                                    <button style={btnStyle} onClick={() => this.handleFetchTransaction(prop.id)}>
                                      <TransactionDetailsCard details={prop}/>
                                    </button>
                                  </GridItem>
                                );
                            })
                          : <center style={{padding: "30px 10px", fontSize: "20px"}}>{'No transactions'}</center>
                        }
                    </div>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={()=>this.props.onClose("viewCardDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                        Close
                    </Button>
                </DialogActions>
                <ConfirmDialog
                  confirmModal={this.state.confirmModal}
                  confirmModalTitle={this.state.confirmModalTitle}
                  confirmModalMsg={this.state.confirmModalMsg}
                  noLabel={this.state.noLabel}
                  yesLabel={this.state.yesLabel}
                  onClose={() => this.handleClose("confirmModal")} 
                  onYesClick={() => this.handleConfirmClick()} />
                <TransactionDetailsDialog
                    transactionDetailsModal={this.state.transactionDetailsModal}
                    transactionDetailsModalMsg={this.state.transactionDetailsModalMsg}
                    onClose={() => this.handleClose("transactionDetailsModal")}  />
                {
                  this.state.cardUpdateDialogModal
                  ? <CardUpdateDialog
                    cardUpdateDialogModalMsg={this.state.cardUpdateDialogModalMsg}
                    cardUpdateDialogModal={this.state.cardUpdateDialogModal}
                    {...this.props}
                    onClose={() => this.handleClose("cardUpdateDialogModal")}  />
                  : ''
                }
            </Dialog>
        )
    }
}

export default withStyles(cardsStyle)(ViewCardDialog);