import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Datetime from "react-datetime";
import moment from 'moment';
import validations from "customs/helpers/validations";
import AlertDialog from "views/Dashboard/Dialogs/AlertDialog.jsx";
import CardCreateDialog from "views/Dashboard/Dialogs/CardCreateDialog.jsx";
import ImageContainer from "views/Components/ImageContainer.jsx";

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import helpers from "customs/helpers/helpers";
import ConfirmDialog from 'customs/components/ConfirmDialog';

import axios from 'axios/axios';
import axiosHelper from "axios/axiosHelper";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class GiftDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    
    let payment_method_select = "";
    if (props.paymentMethods) {
      for (var i=0; i < props.paymentMethods.length; i++) {
        if (i === 0) payment_method_select = props.paymentMethods[i].id;
        if (props.paymentMethods[i].default || props.paymentMethods[i].default === 1) {
          payment_method_select = props.paymentMethods[i].id;
          break;
        }
      }
    }

    this.state = {
      amount: "",
      amount_valid: "",
      note: "",
      payment_method_select: payment_method_select ? payment_method_select : '',
      payment_method_valid: "",
      payment_method_add_valid: "",
      gift_type: "single", //single - recurring
      recurring_type: "weekly", //weekly - bi-monthly - monthly
      recurring_start: moment(),
      alertDialogModal: false,
      alertDialogModalMsg: '',
      confirmModal: false,
      confirmModalTitle: '',
      confirmModalMsg: '',
      noLabel: "Cancel",
      yesLabel: "Ok",
      closeMeOnAlertClose: false,
      cardCreateDialogModal: false,
      access_token: null,
      temporary_token_url: "",
      logo: null,
      selectedIdx: this.props.selectedIdx
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFavouriteClick = this.handleFavouriteClick.bind(this);
    this.handleCreatePaymentClick = this.handleCreatePaymentClick.bind(this);
    this.handleAddCard = this.handleAddCard.bind(this);
    this.handleOnCarouselChange = this.handleOnCarouselChange.bind(this);
  }

  componentWillUnmount() {
      this._ismounted = false;
  }

  componentDidMount () {
    this._ismounted = true;
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);

    if (this.state.closeMeOnAlertClose) this.props.onClose("giftDetailsDialogModal");
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  handleFavouriteClick(event) {
      event.preventDefault();
      this.props.onFavouriteClick(this.props.details.id, !this.props.details.favourite);
  }

  handleOnCarouselChange(index) {
    this.setState({selectedIdx: index});
  }

  validateSubmitTransaction() {
    let cont = true;
    const amount_valid = validations.validateInputHasValue(this.state.amount);
    if (!amount_valid || parseFloat(this.state.amount) <= 0) {
      cont = false;
      this.setState({ "amount_valid": "error" });
    } else {
      this.setState({"amount_valid": "",});
    }
    
    const payment_method_valid = validations.validateInputHasValue('' + this.state.payment_method_select);
    if (!payment_method_valid) {
      cont = false;
      if (this.props.paymentMethods && this.props.paymentMethods.length > 0)
        this.setState({ "payment_method_valid": "error" });
      else
        this.setState({ "payment_method_add_valid": "error" });
    } else {
      this.setState({"payment_method_valid": "", "payment_method_add_valid": ""});
    }

    return cont;
  }

  handleCreatePaymentClick() {
    if (!this.validateSubmitTransaction()) return;

    let card = '';
    for(let i=0; i<this.props.paymentMethods.length; i++) {
      if (this.props.paymentMethods[i].id === this.state.payment_method_select) {
        card = this.props.paymentMethods[i].alias ? this.props.paymentMethods[i].alias : this.props.paymentMethods[i].pan;
        break;
      }
    }

    var x = [];
    x['confirmModal'] = true;
    if (this.state.gift_type === "single") {
      this.setState({...x,
        'confirmModalTitle': 'One Time Gift',
        'confirmModalMsg': 'Please confirm your gift of $' + helpers.formatMoney(this.state.amount, 2, '.', ',') + ' to ' + this.props.details.name + ' using ' + card,
        'yesLabel': 'Give',
      });
    } else {
      this.setState({...x,
        'confirmModalTitle': 'Scheduled Giving',
        'confirmModalMsg': 'Please confirm your scheduled giving of $' +  helpers.formatMoney(this.state.amount, 2, '.', ',') + ' to ' + this.props.details.name + ' using ' + card +
          ', ' + (this.state.recurring_type === "weekly" ? "on a weekly basis" : (this.state.recurring_type === "bi-monthly" ? "every 2 weeks" : "on a monthly basis")),
        'yesLabel': 'Give'
      });
    }
  }

  submitProcessPayment() {
    this.setState({'confirmModal': false});

    const fd = new FormData();
    fd.append('payment_method_id', this.state.payment_method_select);
    fd.append('amount', this.state.amount * 100);
    fd.append('recipient_type', this.props.type);
    if (this.state.note && this.state.note.length > 0) fd.append('note', this.state.note);
    fd.append('recipient_id', this.props.details.id);

    if (this.state.gift_type === "single") {
      this.doProcessSingle(fd);
    } else {
      this.doProcessSchedule(fd);
    }
  }

  doProcessSingle(fd) {
    helpers.showLoading();
    axios(this.props).post("/txn", fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processPayment(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  doProcessSchedule(fd) {
    fd.append('start_at', this.state.recurring_start.format('YYYY-MM-DD'));
    fd.append('recur_period', this.state.recurring_type === "bi-monthly" ? 2 : 1);
    fd.append('recur_period_units', this.state.recurring_type === "monthly" ? "month" : "week");
    
    helpers.showLoading();
    axios(this.props).post("/schedule", fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processPayment(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processPayment(response) {
    helpers.hideLoading();
    let hideAlert = true;
    let msg = this.state.gift_type === "single" ? "Thank you for your gift" : "Thank you for your future gifts";
    if (this.state.gift_type === "single") {
      if (response.data.status === 'declined') msg = 'Transaction declined.';
      else if (response.data.status !== 'approved') msg = 'Transaction error. Please try again.';
      hideAlert = response.data.status === 'approved';
    }
    this.setState({
      "alertDialogModal": true,
      "alertDialogModalMsg": msg,
      "closeMeOnAlertClose": hideAlert
    });
  }

  processErrorAxios(error, prop, err_msg) {
    if (!this._ismounted) return;
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.setState({"alertDialogModal": true, "alertDialogModalMsg": saveDetailsErrors});
    
    axiosHelper.processError(this.isUnmounted, prop, error, (state) => {this.setState(state);}, () => {
      // this.showUsersNotification();
    }, () => {
      // this.props.doLogout({ ...this.props });
    });
  }

  handleCardChange = event => {
    this.setState({ [event.target.name]: event.target.value, "payment_method_select": event.target.value });
  };

  handleGiftTypeChange(type) {
    this.setState({gift_type: type});
  }

  handleRecurringTypeChange(type) {
    this.setState({recurring_type: type});
  }

  handleRecurringStartChange(date) {
    this.setState({recurring_start: date});
  }
  
  handleShowAddCard() {
    if (this.state.access_token && this.state.temporary_token_url) return this.showAddCard();
    this.handleFetchAccessToken();
  }

  handleFetchAccessToken() {
    helpers.showLoading();
    axios(this.props).post("/payment_method/access_token", {}, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processFetchAccessToken(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processFetchAccessToken(response) {
    helpers.hideLoading();
    this.setState({access_token: response.data.access_token, temporary_token_url: response.data.submit_url});
    this.showAddCard();
  }

  showAddCard() {
    this.setState({"cardCreateDialogModal": true});
  }

  handleAddCard(temporary_token, alias, is_default) {
    const fd = new FormData();
    fd.append('temporary_token', temporary_token);
    fd.append('alias', alias);
    fd.append('default', is_default ? 1 : 0);
    axios(this.props).post("/payment_method/create", fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processAddCard(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processAddCard(response) {
    helpers.showLoading();
    this.setState({"cardCreateDialogModal": false, 'payment_method_select': response.data && response.data.id ? response.data.id : this.state.payment_method_select});
    this.props.doFetchCards({ ...this.props });
  }

  render() {
    const { classes } = this.props;
    let details = this.props.details;
    let delay = 500;
    const btnStyle = {background: "transparent", padding: "10px 10px 10px 0px", outline: "0", border: "0", display: "inline-flex", alignItems: "center"};
    return (
        <Dialog
            style={{zIndex: 1200, right: "-35px", left: "-35px"}}
            classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modalSmall}}
            open={this.props.giftDetailsDialogModal}
            keepMounted
            onClose={()=>this.props.onClose("giftDetailsDialogModal")}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle style={{paddingBottom:'0px', minWidth: '250px'}} className={classes.modalBodyBody}>
                {'Create a Gift'}
                <i onClick={(event) => this.handleFavouriteClick(event)} className={(details.favourite === 1 ? 'fas' : 'far') + ' fa-heart'}
                  style={{fontSize: '25px', margin: "0px 03px", float: "right", color: "#D75A4A"}}/>
            </DialogTitle>
            <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{width: "100%", textAlign: "center"}}>
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={false}
                      stopOnHover={true}
                      useKeyboardArrows={true}
                      selectedItem={this.state.selectedIdx}
                      onChange={this.handleOnCarouselChange}
                      showIndicators={details.images ? details.images.length > 1 : false}
                    >
                      {
                        details.images && details.images.length > 0
                          ? details.images.map((prop, key) => {
                              return <ImageContainer
                                delay={delay + 200}
                                type={this.props.type} image_id={prop.id} auth={this.props.auth} key={key} alt_text={prop.alt_text}
                                handleAddCoverToList={this.props.handleAddCoverToList}
                                handleGetCoverFromList={this.props.handleGetCoverFromList}
                                />;
                            })
                          : <ImageContainer
                              type={this.props.type} image_id={details.logo_id ? details.logo_id : ''} auth={this.props.auth} alt_text={"..."}
                              handleAddCoverToList={this.props.handleAddCoverToList}
                              handleGetCoverFromList={this.props.handleGetCoverFromList}
                              />
                      }
                    </Carousel>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <div style={{width: '100%'}}>
                  <table style={{width: '100%', textAlign: "left", padding: "1rem 0rem"}}>
                    <tbody>
                        <tr>
                          <td style={{width: "0.5rem"}}>
                            <i className="fa fa-church" style={{fontSize: '15px', margin: "0px 03px"}}/>
                          </td>
                          <td style={{fontSize: "1.1rem", fontWeight: "bold"}}>
                            {details.name ? details.name : ''}
                          </td>
                        </tr>
                        <tr>
                          {
                            this.props.type === "event"
                            ? null
                            : <td>
                                <i className="fa fa-map-marked" style={{fontSize: '15px', margin: "0px 03px"}}/>
                              </td>
                          }
                          <td colSpan={this.props.type === "event" ? "2" : "1"} style={{fontSize: "1rem"}}>
                            {
                              details.description
                                ? details.description
                                : (details.address1 ? helpers.addLineBreaks(
                                    details.address1 + (details.address2 ? " " + details.address2 : "") + ", " +
                                    details.city + ", " + details.state + "<br>" + details.country + " - " + details.postal_code
                                  ) : '')
                            }
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    success={this.state.amount_valid === "success"}
                    error={this.state.amount_valid === "error"}
                    labelText="Amount"
                    id="amount"
                    helperText={ this.state.amount_valid === "error" && this.state.amount ? "Please enter an amount greater than 0" : undefined }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: this.handleChange,
                      value: this.state.amount
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="payment-method"
                    className={classes.selectLabel}
                  >
                    Choose a Card
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.payment_method_select}
                    onChange={this.handleCardChange}
                    inputProps={{
                      name: "payment_method_select",
                      id: "payment-method"
                    }}
                  >
                    <MenuItem key={999}
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose a Card
                    </MenuItem>
                    {
                      this.props.paymentMethods.map((prop, key) => {
                        return <MenuItem key={key}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={prop.id}
                        >
                          {(prop.alias ? (prop.alias + ' ') : '') + '(' + prop.pan + ')'}
                        </MenuItem>
                      })
                    }
                  </Select>
                  {
                    this.state.payment_method_valid !== "error" ? '' : <label style={{color: '#f44336', fontWeight: "300", fontSize: "0.75rem"}}>{'Please select a card'}</label>
                  }
                  {
                    this.state.payment_method_add_valid !== "error" ? '' : <label style={{color: '#f44336', fontWeight: "300", fontSize: "0.75rem"}}>{'Please add a card'}</label>
                  }
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{display: 'inline-flex', alignItems: 'center'}}>
                  <button style={btnStyle} onClick={() => this.handleShowAddCard()}>
                    <i className="fa fa-plus-circle" style={{fontSize: '25px', margin: "0px 03px"}}/> Add Card
                  </button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                  <span style={{margin: "0px 5px"}}>
                    <Button
                      color={this.state.gift_type === 'single' ? "primary" : "default"}
                      round
                      className={classes.marginRight}
                      onClick={() => this.handleGiftTypeChange("single")}
                    >
                      Give One Time
                    </Button>
                  </span>
                  <span style={{margin: "0px 5px"}}>
                    <Button
                      color={this.state.gift_type === 'recurring' ? "primary" : "default"}
                      round
                      className={classes.marginRight}
                      onClick={() => this.handleGiftTypeChange("recurring")}
                    >
                      Make a Recurring Gift
                    </Button>
                  </span>
                </GridItem>
                {
                  this.state.gift_type === "recurring"
                  ? <div style={{width: "100%"}}>
                      <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                        <span style={{margin: "0px 5px"}}>
                          <Button
                            color={this.state.recurring_type === "weekly" ? "info" : "default"}
                            round
                            className={classes.marginRight}
                            onClick={() => this.handleRecurringTypeChange("weekly")}
                          >
                            Every Week
                          </Button>
                        </span>
                        <span style={{margin: "0px 5px"}}>
                          <Button
                            color={this.state.recurring_type === "bi-monthly" ? "info" : "default"}
                            round
                            className={classes.marginRight}
                            onClick={() => this.handleRecurringTypeChange("bi-monthly")}
                          >
                            Every 2 Weeks
                          </Button>
                        </span>
                        <span style={{margin: "0px 5px"}}>
                          <Button
                            color={this.state.recurring_type === "monthly" ? "info" : "default"}
                            round
                            className={classes.marginRight}
                            onClick={() => this.handleRecurringTypeChange("monthly")}
                          >
                            Every Month
                          </Button>
                        </span>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <table align="center" style={{float: "center"}}>
                          <tbody>
                            <tr>
                              <td>
                                Starts
                              </td>
                              <td>
                                <Datetime
                                  value={this.state.recurring_start}
                                  closeOnSelect={true}
                                  isValidDate={(current) => current.isAfter( Datetime.moment().subtract( 1, 'day' ) )}
                                  onChange={(date) => this.handleRecurringStartChange(date)}
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  inputProps={{ placeholder: "Starts" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </GridItem>
                    </div>
                  : ''
                }
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Personal Giving Note"
                    id="note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      onChange: this.handleChange,
                      value: this.state.note
                    }}
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button onClick={()=>this.props.onClose("giftDetailsDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                    Close
                </Button>
                <Button onClick={this.handleCreatePaymentClick} color="success" simple
                    className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                >
                    Submit
                </Button>
            </DialogActions>
            <ConfirmDialog
              confirmModal={this.state.confirmModal}
              confirmModalTitle={this.state.confirmModalTitle}
              confirmModalMsg={this.state.confirmModalMsg}
              noLabel={this.state.noLabel}
              yesLabel={this.state.yesLabel}
              onClose={() => this.handleClose("confirmModal")} 
              onYesClick={() => this.submitProcessPayment()} />
            <AlertDialog
              alertDialogModal={this.state.alertDialogModal}
              alertDialogModalMsg={this.state.alertDialogModalMsg}
              onClose={() => this.handleClose("alertDialogModal")}  />
            {
              this.state.cardCreateDialogModal
              ? <CardCreateDialog
                details={this.state.cardCreateDialogModal}
                cardCreateDialogModal={this.state.cardCreateDialogModal}
                access_token={this.state.access_token}
                temporary_token_url={this.state.temporary_token_url}
                handleAddCard={this.handleAddCard}
                auth={this.props.auth}
                onClose={() => this.handleClose("cardCreateDialogModal")}  />
              : ''
            }
        </Dialog>
    )
  }
}

export default withStyles(cardsStyle)(GiftDetailsDialog);