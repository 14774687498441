// import axios from 'axios/axiosAuth';
import axiosWithToken from 'axios/axios';
import * as authActionTypes from './authActionTypes';
// import { http_post } from '../httpPostClientAction';
import { http_get } from '../httpGetClientAction';

export const doFetchParishes = (name, lat, long, fav, props) => {
    let params = '';
    if (name.length > 0) params += '&name=' + name;
    if ((''+lat).length > 0) params += '&lat=' + lat;
    if ((''+long).length > 0) params += '&long=' + long;
    if (fav.length > 0) params += '&favourites=' + fav;
    return (dispatch, getState) => {
        callFetchParishes(params, props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchParishes(params, props, callback) {
    http_get(axiosWithToken(props), '/parish?' + params, authActionTypes.FETCH_PARISHES_SUCCESS, authActionTypes.FETCH_PARISHES_ERROR, callback);
}

export const doFetchEvents = (name, fav, props) => {
    let params = '';
    if (name.length > 0) params += '&name=' + name;
    if (fav.length > 0) params += '&favourites=' + fav;
    return (dispatch, getState) => {
        callFetchEvents(params, props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchEvents(params, props, callback) {
    http_get(axiosWithToken(props), '/event?' + params, authActionTypes.FETCH_EVENTS_SUCCESS, authActionTypes.FETCH_EVENTS_ERROR, callback);
}

export const doFetchTransactions = (props) => {
    return (dispatch, getState) => {
        callFetchTransactions(props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchTransactions(props, callback) {
    http_get(axiosWithToken(props), '/txn', authActionTypes.FETCH_TRANSACTIONS_SUCCESS, authActionTypes.FETCH_TRANSACTIONS_ERROR, callback);
}

export const doFetchSchedules = (props) => {
    return (dispatch, getState) => {
        callFetchSchedules(props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchSchedules(props, callback) {
    http_get(axiosWithToken(props), '/schedule', authActionTypes.FETCH_SCHEDULES_SUCCESS, authActionTypes.FETCH_SCHEDULES_ERROR, callback);
}

export const doFetchCards = (props) => {
    return (dispatch, getState) => {
        callFetchCards(props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchCards(props, callback) {
    http_get(axiosWithToken(props), '/payment_method', authActionTypes.FETCH_CARDS_SUCCESS, authActionTypes.FETCH_CARDS_ERROR, callback);
}