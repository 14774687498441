import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import changeAddressStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import EmailIcon from "@material-ui/icons/Email";
import ConfirmDialog from 'customs/components/ConfirmDialog';

import { connect } from 'react-redux';
import { doFetchUserDetails, doUpdateProfile } from '../../store/actions/authActions';

import AuthService from 'customs/auth/AuthService';
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";

class ChangeAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tr: false,
      confirmModal: false,
      confirmModalTitle: '',
      address_one: this.props.me && this.props.me.address1 ? this.props.me.address1 : '',
      address_two: this.props.me && this.props.me.address2 ? this.props.me.address2 : '',
      city: this.props.me && this.props.me.city ? this.props.me.city : '',
      state: this.props.me && this.props.me.state ? this.props.me.state : '',
      post_code: this.props.me && this.props.me.postal_code ? this.props.me.postal_code : '',
      country: this.props.me && this.props.me.country ? this.props.me.country : ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  setAddressFromProps() {
    if (this.props.me) {
      this.setState({
        address_one: this.props.me.address1 ? this.props.me.address1 : '',
        address_two: this.props.me.address2 ? this.props.me.address2 : '',
        city: this.props.me.city ? this.props.me.city : '',
        state: this.props.me.state ? this.props.me.state : '',
        post_code: this.props.me.postal_code ? this.props.me.postal_code : '',
        country: this.props.me.country ? this.props.me.country : ''
      });
    }
  }

  componentDidMount () {
    if (!this.props.me) {
      helpers.showLoading();
      this.props.doFetchUserDetails({ ...this.props });
    } else {
      this.setAddressFromProps();
    }
  }

  handleUpdateProfileClick () {
    let cont = true;
    const is_valid_address_one = validations.validateInputHasValue(this.state.address_one);
    const is_valid_city = validations.validateInputHasValue(this.state.city);
    const is_valid_state = validations.validateInputHasValue(this.state.state);
    const is_valid_postcode = validations.validateInputHasValue(this.state.post_code);
    const is_valid_country = validations.validateInputHasValue(this.state.country);

    if ((is_valid_address_one || is_valid_city || is_valid_state || is_valid_postcode || is_valid_country)
          && !(is_valid_address_one && is_valid_city && is_valid_state && is_valid_postcode && is_valid_country)) {
      cont = false;

      if (!is_valid_address_one) this.setState({ "address_one_valid": "error", "address_one_error": validations.requiredErrorMsg() });
      else this.setState({ "address_one_valid": "", "address_one_error": "" });

      if (!is_valid_city) this.setState({ "city_valid": "error", "city_error": validations.requiredErrorMsg() });
      else this.setState({ "city_valid": "", "city_error": "" });

      if (!is_valid_state) this.setState({ "state_valid": "error", "state_error": validations.requiredErrorMsg() });
      else this.setState({ "state_valid": "", "state_error": "" });

      if (!is_valid_postcode) this.setState({ "postcode_valid": "error", "postcode_error": validations.requiredErrorMsg() });
      else this.setState({ "postcode_valid": "", "postcode_error": "" });

      if (!is_valid_country) this.setState({ "country_valid": "error", "country_error": validations.requiredErrorMsg() });
      else this.setState({ "country_valid": "", "country_error": "" });
    }

    if (cont) {
      var x = [];
      x['confirmModal'] = true;
      this.setState({...x, 'confirmModalTitle': 'Update Address'});
    }
  }

  doChangeAddressClick() {
    helpers.showLoading();
    this.handleClose("confirmModal");

    let me = {};
    me['name'] = this.props.me.name;
    me['email'] = this.props.me.email;
    me['phone'] = this.props.me.phone;
    me['address1'] = this.state.address_one;
    me['address2'] = this.state.address_two;
    me['city'] = this.state.city;
    me['state'] = this.state.state;
    me['country'] = this.state.country;
    me['postal_code'] = this.state.post_code;

    this.props.doUpdateProfile(me, { ...this.props });
  }

  render() {
    const authService = new AuthService(this.state, this.props);
    if (!authService.getToken())
      return (<div><Redirect to="/auth/login"/></div>);

    let address_one_valid = this.state.address_one_valid;
    let address_one_error = this.state.address_one_valid === "error" && this.state.address_one_error ? this.state.address_one_error : undefined;
    let address_two_valid = this.state.address_two_valid;
    let address_two_error = this.state.address_two_valid === "error" && this.state.address_two ? 'Address Line 2 is invalid' : undefined;
    let city_valid = this.state.city_valid;
    let city_error = this.state.city_valid === "error" && this.state.city_error ? this.state.city_error : undefined;
    let state_valid = this.state.state_valid;
    let state_error = this.state.state_valid === "error" && this.state.state_error ? this.state.city_error : undefined;
    let postcode_valid = this.state.postcode_valid;
    let postcode_error = this.state.postcode_valid === "error" && this.state.postcode_error ? this.state.postcode_error : undefined;
    let country_valid = this.state.country_valid;
    let country_error = this.state.country_valid === "error" && this.state.country_error ? this.state.country_error : undefined;

    const profileUpdated = this.props.auth && this.props.auth.authMessage ? this.props.auth.authMessage : '';
    if (profileUpdated) {
      this.props.auth.authMessage = '';
      setTimeout( function() {
        this.setState({profileUpdated,});
        if (profileUpdated === 'Profile Updated')
          this.setState({new_email: "", confirm_email: ""});
        helpers.hideLoading();
      }.bind(this), 100 );
    }

    if (this.props.prop_updated.updated) {
      this.props.prop_updated.updated = false;
      setTimeout( function() {
        this.setAddressFromProps();
      }.bind(this), 100 );
    }

    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <EmailIcon />
                </CardIcon>
              </CardHeader>
              {
                this.state.profileUpdated
                  ? <CardBody style={{paddingBottom:'0px'}}>
                    <h4 className={classes.textCenter} id="reset_password_status" style={{color: this.state.profileUpdated === 'Profile Updated' ? '#4caf50' : 'red', marginBottom:'0px', paddingBottom:'0px'}}>
                      {this.state.profileUpdated === 'Profile Updated' ? "Address updated" : this.state.profileUpdated}
                    </h4>
                    </CardBody>
                  : ''
              }
              <CardBody>
                <CustomInput
                  success={address_one_valid === "success"}
                  error={address_one_valid === "error"}
                  labelText="Address Line 1"
                  id="address_one"
                  helperText={ address_one_error }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    onChange: this.handleChange,
                    value: this.state.address_one.length > 0 ? this.state.address_one : ''
                  }}
                />
                <CustomInput
                  success={address_two_valid === "success"}
                  error={address_two_valid === "error"}
                  labelText="Address Line 2"
                  id="address_two"
                  helperText={ address_two_error }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    onChange: this.handleChange,
                    value: this.state.address_two.length > 0 ? this.state.address_two : ''
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={city_valid === "success"}
                      error={city_valid === "error"}
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ city_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: this.state.city.length > 0 ? this.state.city : ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={state_valid === "success"}
                      error={state_valid === "error"}
                      labelText="State"
                      id="state"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ state_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: this.state.state.length > 0 ? this.state.state : ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={postcode_valid === "success"}
                      error={postcode_valid === "error"}
                      labelText="Post Code"
                      id="post_code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ postcode_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: this.state.post_code.length > 0 ? this.state.post_code : ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={country_valid === "success"}
                      error={country_valid === "error"}
                      labelText="Country"
                      id="country"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ country_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: this.state.country.length > 0 ? this.state.country : ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button color="primary" className={classes.updateProfileButton} onClick={() => this.handleUpdateProfileClick("confirmModal")}>
                  Update Address
                </Button>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <ConfirmDialog
            confirmModal={this.state.confirmModal}
            confirmModalTitle={this.state.confirmModalTitle}
            onClose={() => this.handleClose("confirmModal")} 
            onYesClick={() => this.doChangeAddressClick()} />
        </GridContainer>
      </div>
    );
  }
}

ChangeAddress.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    me: state.auth.me,
    force_render: Math.random(),
    prop_updated: {updated: true}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchUserDetails: (props) => dispatch(doFetchUserDetails(props)),
    doUpdateProfile: (params, props) => dispatch(doUpdateProfile(params, props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(changeAddressStyle)(ChangeAddress));
