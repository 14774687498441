import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

import { connect } from 'react-redux';
import axios from 'axios/axiosAuth';
import helpers from "customs/helpers/helpers";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openProfile: false
    };
  }

  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };

  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  handleGotoChangeEmail = () => {
    this.handleCloseProfile();
    if (!this.activeRoute('/change/email')) this.props.history.push('/admin/change/email');
  };

  handleGotoChangePassword = () => {
    this.handleCloseProfile();
    if (!this.activeRoute('/change/password')) this.props.history.push('/admin/change/password');
  };

  handleGotoUpdatePhone = () => {
    this.handleCloseProfile();
    if (!this.activeRoute('/update/phone')) this.props.history.push('/admin/update/phone');
  };

  handleGotoUpdateAddress = () => {
    this.handleCloseProfile();
    if (!this.activeRoute('/update/address')) this.props.history.push('/admin/update/address');
  };

  handleGotoPrivacyPolicy = () => {
    this.handleCloseProfile();
    helpers.showLoading();
    axios.get("/app/endpoints")
      .then(response => {
        helpers.hideLoading();
        let privacy_endpoint = '';
        try {
          let host = window.location.href.split('//');
          let current = host[0] + '//' + host[1].split('/')[0];
          Object.keys(response.data).forEach(function(key) {
              if (response.data[key]['api_endpoint'].indexOf(current) >= 0) {
                privacy_endpoint = response.data[key]['privacy_endpoint'];
              }
          });
        } catch(e) {}
        window.open(privacy_endpoint, "_blank");
      })
      .catch(error => helpers.hideLoading());
  };

  render() {
    const { classes, rtlActive } = this.props;
    const { openProfile } = this.state;
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div className={wrapper}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorProfile = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickProfile}
                className={classes.linkText}
              >
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleGotoChangeEmail.bind(this)}
                        className={dropdownItem}
                      >
                        <i className="fa fa-envelope fa-fw"/> {rtlActive ? "" : "Change Email Address"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleGotoChangePassword.bind(this)}
                        className={dropdownItem}
                      >
                        <i className="fa fa-lock fa-fw"/> {rtlActive ? "" : "Change Password"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleGotoUpdatePhone.bind(this)}
                        className={dropdownItem}
                      >
                        <i className="fa fa-mobile-alt fa-fw"/> {rtlActive ? "" : "Update Phone"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleGotoUpdateAddress.bind(this)}
                        className={dropdownItem}
                      >
                        <i className="fa fa-map-marked-alt fa-fw"/> {rtlActive ? "" : "Update Address"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleGotoPrivacyPolicy.bind(this)}
                        className={dropdownItem}
                      >
                        <i className="fa fa-user-shield fa-fw"/> {rtlActive ? "" : "Privacy Policy"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth.data
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks)));