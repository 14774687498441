import * as authActionTypes from '../actions/authActionTypes';
import helpers from "customs/helpers/helpers";

const initState = {
    data: {
        auth_token: null,
        authMessage: '',
        authStatus: '',
        resetLinkMessage: '',
        resetPasswordMessage: ''
    }
};

const executeLoginSuccess = (state, action) => {
    return {
        ...state,
    }
}

const executeLoginError = (state, action) => {
    state.data.authMessage = state.data && state.data.error && state.data.error.toLowerCase() !== 'unauthorized'
        ? state.data.error : 'Invalid Credentials. Please check email and password.';
    return {
        ...state,
    }
}

const executeDefault = (state, action) => {
    return {
        ...state,
    }
}

const executeLogout = (state, action) => {
    let new_auth = initState;
    new_auth.data = initState;
    new_auth.me = null;
    new_auth.parishes = null;
    new_auth.events = null;
    new_auth.cards = null;
    new_auth.transactions = null;
    new_auth.schedules = null;
    return {
        ...new_auth,
    }
}

const executeUpdateProfileSuccess = (state, action, auth) => {
    let new_auth = initState;
    new_auth.data = auth;
    new_auth.data.authMessage = 'Profile Updated';
    new_auth.me = state.data;
    return {
        ...new_auth,
    }
}

const executeUpdateProfileError = (state, action, auth) => {
    var err = helpers.getErrorMessage(action);
    if (err === false)
        state = initState
    else {
        state.data = action.props.auth;
        state.data.authMessage = err;
    }
    return {
        ...state,
    }
}

const executeChangePasswordSuccess = (state, action) => {
    state.data.authMessage = 'Password changed';
    return {
        ...state,
    }
}

const executeChangePasswordError = (state, action) => {
    var err = helpers.getErrorMessage(action);
    if (err === false)
        state = initState
    else {
        state.data = action.props.auth;
        state.data.authMessage = err;
    }
    return {
        ...state,
    }
}

const executeSendResetEmailSuccess = (state, action) => {
    state.data.resetLinkMessage = state.data && state.data.message ? state.data.message : 'Reset email is being sent if this user is registered.';
    state.data.authStatus = '#4caf50';
    return {
        ...state,
    }
}

const executeSendResetEmailError = (state, action) => {
    state.data.resetLinkMessage = state.data && state.data.error ? state.data.error : 'Unable to send password reset link to your e-mail.';
    state.data.authStatus = 'red';
    return {
        ...state,
    }
}

const executeResetPasswordSuccess = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.status ? state.data.status : 'Password successfully changed. Please login with your new password.';
    state.data.authStatus = '#4caf50';
    return {
        ...state,
    }
}

const executeResetPasswordError = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.error ? state.data.error : 'The password reset token is invalid or has expired.';
    state.data.authStatus = 'red';
    return {
        ...state,
    }
}

const executeUpdateProfileData = (state, action) => {
    return {
        ...state,
    }
}

const executeFetchMeSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    new_auth.me = state.data;
    return {
        ...new_auth,
    }
}

const executeFetchMeError = (state, action, auth) => {
    let new_auth = fixStateData(action);
    return {
        ...new_auth,
    }
}

const fixStateData = (action) => {
    let props = action.props;
    let new_auth = initState;
    new_auth.data = props.auth;
    new_auth.me = props.me ? props.me : null;
    
    if (action.response && action.response.data && action.response.data.exception)
        new_auth.data['attempts_error'] = action.response.data.message ? action.response.data.message : null;

    return new_auth;
}

const executeFetchParishesSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    let parishes = state.data;
    if (!(parishes.length > 0 && parishes[0].distance)) parishes.sort(function(a, b) {return a.id - b.id;});
    new_auth.parishes = parishes;
    return {
        ...new_auth,
    }
}

const executeFetchParishesError = (state, action, auth) => {
    let new_auth = fixStateData(action);
    return {
        ...new_auth,
    }
}

const executeFetchEventsSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    let events = state.data;
    events.sort(function(a, b) {return a.id - b.id;});
    new_auth.events = events;
    return {
        ...new_auth,
    }
}

const executeFetchEventsError = (state, action, auth) => {
    let new_auth = fixStateData(action);
    return {
        ...new_auth,
    }
}

const executeFetchTransactionsSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    let transactions = state.data;
    transactions.sort(function(a, b) {return a.id - b.id;});
    new_auth.transactions = transactions;
    return {
        ...new_auth,
    }
}

const executeFetchSchedulesSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    let schedules = state.data;
    schedules.sort(function(a, b) {return a.id - b.id;});
    new_auth.schedules = schedules;
    return {
        ...new_auth,
    }
}

const executeFetchCardsSuccess = (state, action, auth) => {
    let new_auth = fixStateData(action);
    let cards = state.data;
    cards.sort(function(a, b) {return a.id - b.id;});
    new_auth.cards = cards;
    return {
        ...new_auth,
    }
}

const executeRegistrationSuccess = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.status ? state.data.status : 'Successfully registered.';
    state.data.authStatus = '#4caf50';
    return {
        ...state,
    }
}

const executeRegistrationError = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.error ? state.data.error : 'Registration failed, please try again.';
    state.data.authStatus = 'red';
    return {
        ...state,
    }
}

const executeRefreshTokenSuccess = (state, action) => {
    let new_auth = fixStateData(action);
    new_auth.data.auth_token = action.response.data.auth_token;
    return {
        ...new_auth,
    }
}

const executeTokenLoginError = (state, action) => {
    state.data.authMessage = state.data && state.data.error && state.data.error.toLowerCase() !== 'unauthorized'
        ? state.data.error : 'Invalid Token. Please login with your email and password.';
    return {
        ...state,
    }
}

const authReducer = (state = initState, action) => {
    if (action.response && action.response.data) state.data = action.response.data;
    initState.data.authMessage = '';
    state.data.authMessage = '';
    switch (action.type) {
        case authActionTypes.LOGIN_SUCCESS:
            return executeLoginSuccess(state, action);
        case authActionTypes.LOGIN_ERROR:
            return executeLoginError(state, action);
        case authActionTypes.DO_LOGOUT:
            return executeLogout(initState, action);
        case authActionTypes.UPDATE_PROFILE_SUCCESS:
            return executeUpdateProfileSuccess(state, action, action.props.auth);
        case authActionTypes.UPDATE_PROFILE_ERROR:
            return executeUpdateProfileError(state, action, action.props.auth);
        case authActionTypes.CHANGE_PASSWORD_SUCCESS:
            return executeChangePasswordSuccess(state, action);
        case authActionTypes.CHANGE_PASSWORD_ERROR:
            return executeChangePasswordError(state, action);
        case authActionTypes.SEND_RESET_EMAIL_SUCCESS:
            return executeSendResetEmailSuccess(state, action);
        case authActionTypes.SEND_RESET_EMAIL_ERROR:
            return executeSendResetEmailError(state, action);
        case authActionTypes.RESET_PASSWORD_SUCCESS:
            return executeResetPasswordSuccess(state, action);
        case authActionTypes.RESET_PASSWORD_ERROR:
            return executeResetPasswordError(state, action);
        case authActionTypes.UPDATE_PROFILE_DATA:
            return executeUpdateProfileData(state, action);
        case authActionTypes.FETCH_ME_SUCCESS:
            return executeFetchMeSuccess(state, action, action.props.auth);
        case authActionTypes.FETCH_ME_ERROR:
            return executeFetchMeError(state, action, action.props.auth);
        case authActionTypes.FETCH_PARISHES_SUCCESS:
            return executeFetchParishesSuccess(state, action, action.props.auth);
        case authActionTypes.FETCH_PARISHES_ERROR:
        case authActionTypes.FETCH_CARDS_ERROR:
        case authActionTypes.FETCH_SCHEDULES_ERROR:
        case authActionTypes.FETCH_TRANSACTIONS_ERROR:
            return executeFetchParishesError(state, action, action.props.auth);
        case authActionTypes.FETCH_EVENTS_SUCCESS:
            return executeFetchEventsSuccess(state, action, action.props.auth);
        case authActionTypes.FETCH_EVENTS_ERROR:
            return executeFetchEventsError(state, action, action.props.auth);
        case authActionTypes.FETCH_TRANSACTIONS_SUCCESS:
            return executeFetchTransactionsSuccess(state, action, action.props.auth);
        case authActionTypes.FETCH_SCHEDULES_SUCCESS:
            return executeFetchSchedulesSuccess(state, action, action.props.auth);
        case authActionTypes.FETCH_CARDS_SUCCESS:
            return executeFetchCardsSuccess(state, action, action.props.auth);
        case authActionTypes.REGISTRATION_SUCCESS:
            return executeRegistrationSuccess(state, action);
        case authActionTypes.REGISTRATION_ERROR:
            return executeRegistrationError(state, action);
        case authActionTypes.DO_REFRESH_TOKEN:
            return executeRefreshTokenSuccess(state, action);
        case authActionTypes.TOKEN_LOGIN_ERROR:
            return executeTokenLoginError(state, action);

        default:
            return executeDefault(state, action);
    }
}

export default authReducer