import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";

class ConfirmDialog extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                style={{zIndex: 10000, right: "-35px", left: "-35px"}}
                classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modalSmall}}
                open={this.props.confirmModal}
                keepMounted
                onClose={()=>this.props.onClose("confirmModal")}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle style={{paddingBottom:'0px'}} className={classes.modalBody + " " + classes.modalSmallBody}>
                    {this.props.confirmModalTitle ?  this.props.confirmModalTitle: 'Confirm'}
                </DialogTitle>
                <DialogContent id="small-modal-slide-description" className={classes.modalBody + " " + classes.modalSmallBody}>
                    <h5 style={{ minWidth: '200px', marginBottom: '0px' }}>{this.props.confirmModalMsg ?  this.props.confirmModalMsg: 'Are you sure?'}</h5>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={()=>this.props.onClose("confirmModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                        {this.props.noLabel ?  this.props.noLabel: 'No'}
                    </Button>
                    <Button onClick={this.props.onYesClick} color="success" simple
                        className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                    >
                        {this.props.yesLabel ?  this.props.yesLabel: 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(userProfileStyles)(ConfirmDialog);