import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'canvas-toBlob';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

import { Provider } from 'react-redux';
import store from './store/store.js';

import "@fortawesome/fontawesome-free/css/all.css"

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
