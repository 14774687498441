import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Constants from 'customs/config/Constants';

class CompanyLogo extends React.Component {

    render() {
        return <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={this.props.gridWidth ? this.props.gridWidth : 4}>
                <div style={{float: "left", textAlign: "left"}}>
                    <p style={{margin: "0px", color: "#999999", fontWeight: "bold", fontSize: "0.8rem"}}>Supported By</p>
                    <img src={Constants.API_URL + '/app/supporter_logo?' + Math.random()} alt="..." style={{height: '3rem'}} />
                </div>
                <div style={{float: "right", textAlign: "right"}}>
                    <p style={{margin: "0px", color: "#999999", fontWeight: "bold", fontSize: "0.8rem"}}>Powered By</p>
                    <img src={process.env.PUBLIC_URL + '/images/powered_by.png'} alt="..." style={{width: '7rem'}} />
                </div>
            </GridItem>
        </GridContainer>
    }

}

export default CompanyLogo;