import axios from 'axios';
import Constants from 'customs/config/Constants';
import { doLogout, doRefreshToken } from '../store/actions/authActions';
import store from '../store/store.js';

const axiosAuth = (props) => {
    const instance = axios.create({
        baseURL: Constants.API_URL,
    });
    instance.interceptors.request.use(function (config) {
        const auth_token = props.auth.auth_token ? props.auth.auth_token : '';
        config.headers['Authorization'] = 'Bearer ' + auth_token;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    instance.interceptors.response.use((response) => {
        return response
    }, function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401) {
            const auth_token = store.getState().auth.data.auth_token ? store.getState().auth.data.auth_token : (props.auth.auth_token ? props.auth.auth_token : '');
            return axios.post(Constants.API_URL + '/auth/refresh', {}, {headers: {"Content-Type": "application/x-www-form-urlencoded", "Authorization": 'Bearer ' + auth_token}})
                .then(res => {
                    store.dispatch(doRefreshToken(res, props));
                    if (res.status === 200) {
                        originalRequest.headers['Authorization'] = 'Bearer ' + res.data.auth_token;
                        return axios(originalRequest);
                    }
                    return Promise.reject('');
                })
                .catch(error => {
                    store.dispatch(doLogout(props));
                    try {setTimeout(window.location.replace('/auth/login'), 100);} catch(e) {}
                    return Promise.reject('');
                });
        }
        return Promise.reject(error);
     });

    return instance;
}
 
export default axiosAuth;