import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import helpers from "customs/helpers/helpers";

class ScheduleDetailsCard extends React.Component {
    setFrequencyDisplay(recur_period, recur_period_units) {
        return recur_period === 1
            ? recur_period_units.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); }) + 'ly'
            : 'Every ' + recur_period + ' weeks';
    }

    setStatusColor(status) {
        return status === 'paused' ? 'orange' : (status === 'stopped' ? 'red' : (status === 'held' ? 'black' : 'green'));
    }

    render() {
        return <Card style={{ marginTop: '20px', marginBottom: '5px' }}>
            <CardBody style={{padding: '10px 5px'}}>
                <table style={{width: '100%', textAlign: "left"}}>
                    <tbody>
                        <tr>
                            <td style={{fontWeight: "bold"}}>
                                {this.props.details && this.props.details.recipient && this.props.details.recipient.name ? this.props.details.recipient.name : ''}
                            </td>
                            <td colSpan={2} style={{fontSize: "15px", color: this.setStatusColor(this.props.details.status), textAlign: 'right'}}>
                                {this.props.details.status}
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: '90%'}}>
                                {this.props.details.created_at.split(' ')[0]}
                            </td>
                            <td rowSpan={2} style={{width: '1%', fontWeight: "bold", textAlign: "right", verticalAlign: 'top', color: this.setStatusColor(this.props.details.status)}}>
                                ${helpers.formatMoney(this.props.details.amount / 100, 2, '.', ',')}
                            </td>
                            <td rowSpan={2} style={{width: '1%', fontSize: "15px", verticalAlign: 'top', textAlign: "right"}}>
                                <i className="fa fa-arrow-right" style={{fontSize: '15px', margin: "0px 03px"}}/>
                            </td>
                        </tr> 
                        <tr>
                            <td>
                                Frequency: {this.setFrequencyDisplay(this.props.details.recur_period, this.props.details.recur_period_units)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </CardBody>
        </Card>
    }
}

export default ScheduleDetailsCard;