import helpers from "customs/helpers/helpers";

const axiosHelper = {
    processError(isUnmounted, prop, error, stateCallback, doLogoutCallback) {
        if (isUnmounted) return;
        if (prop !== null) stateCallback({prop: []});
        var default_err = helpers.getErrorMessage(error);
        if (default_err === false) {
            if (doLogoutCallback !== null) doLogoutCallback();
        } else {
            if (stateCallback !== null) stateCallback({ btnDisabled: false, doneModal: false, doneModalMsg: default_err, doneModalBtn: 'OK', tr_color: "danger" });
        }
        helpers.hideLoading();
    }
}

export default axiosHelper;