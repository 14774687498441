import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// core components
import Button from "components/CustomButtons/Button.jsx";
import axios_generic from 'axios';
import axiosHelper from "axios/axiosHelper";
import CreditCard from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AlertDialog from "views/Dashboard/Dialogs/AlertDialog.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from 'moment';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from 'views/Components/CardUtils';

class CardCreateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alias_valid: "",
      alias_error: "",
      alias: "",
      default: false,
      access_token: this.props.access_token,
      temporary_token_url: this.props.temporary_token_url,
      alertDialogModal: false,
      alertDialogModalMsg: '',

      number_valid: '',
      name_valid: '',
      expiry_valid: '',
      cvc_valid: '',
      number: '',
      name: '',
      expiry: '',
      cvc: '',
      issuer: '',
      focused: '',
      formData: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleGetCardToken = this.handleGetCardToken.bind(this);
    this.handleCardDetailsChange = this.handleCardDetailsChange.bind(this);
    this.processGetCardToken = this.processGetCardToken.bind(this);
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      if (issuer === 'dinersclub') issuer = 'diners';
      if (issuer === 'unionpay') issuer = 'cup';
      this.setState({ issuer });
    } else {
      this.setState({ issuer: '' });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputBlur = ({ target }) => {
    this.setState({focused: 'card',});
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value, [target.name + "_valid"]: "", [target.name + "_error"]: "" });
  };

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  handleCardDetailsChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  doValidateCard() {
    let cont = true;

    if (this.state.issuer && this.state.issuer.length > 0) {
      this.setState({"number_valid" : "", "number_error": ""});
    } else {
      cont = false;
      this.setState({"number_valid" : "error", "number_error": "Credit card number is invalid"});
    }
    const is_cardholder = validations.validateInputHasValue(this.state.name);
    if (!is_cardholder) {
      cont = false;
      this.setState({ "name_valid": "error", "name_error": validations.requiredErrorMsg() });
    } else {
      this.setState({"name_valid" : "", "name_error": ""});
    }
    const is_expiry = validations.validateInputHasValue("" + this.state.expiry);
    if (!is_expiry) {
      cont = false;
      this.setState({ "expiry_valid": "error", "expiry_error": validations.requiredErrorMsg() });
    } else {
      //check valid date
      let exp = this.state.expiry.replace(/\s/g, '').split('/');
      let exp_valid = exp.length > 1;
      if (exp_valid) {
        var creditCardDate = moment(this.state.expiry.replace(/\s/g, ''), "MM/YY");
        var today = moment();
        exp_valid = creditCardDate.isValid() && (today < creditCardDate.add(1, 'months'));
      }
      if (exp_valid) {
        this.setState({"expiry_valid" : "", "expiry_error": ""});
      } else {
        cont = false;
        this.setState({ "expiry_valid": "error", "expiry_error": "Please check expiration date" });
      }
    }
    const is_cvc = validations.validateInputHasValue("" + this.state.cvc);
    if (!is_cvc) {
      cont = false;
      this.setState({ "cvc_valid": "error", "cvc_error": validations.requiredErrorMsg() });
    } else if (this.state.cvc.length < 3) {
      cont = false;
      this.setState({ "cvc_valid": "error", "cvc_error": "Please check value" });
    } else {
      this.setState({"cvc_valid" : "", "cvc_error": ""});
    }

    return cont;
  }

  handleGetCardToken() {
    if (!this.doValidateCard()) return;
    helpers.showLoading();

    const fd = new FormData();
    var creditCardDate = moment(this.state.expiry.replace(/\s/g, ''), "MM/YY");
    fd.append('temporary_access', this.state.access_token);
    fd.append('card_number', this.state.number.replace(/\s/g, ''));
    fd.append('card_holder', this.state.name);
    fd.append('card_verification_code', this.state.cvc);
    fd.append('card_expiry_month', creditCardDate.format('MM'));
    fd.append('card_expiry_year', creditCardDate.format('YYYY'));
    fd.append('payment_method', this.state.issuer.toUpperCase());
    axios_generic.post(this.state.temporary_token_url, fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processGetCardToken(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processGetCardToken(response) {
    this.props.handleAddCard(response.data.temporary_token, this.state.alias ? this.state.alias : "", this.state.default ? 1 : 0);
  }

  processErrorAxios(error, prop, err_msg) {
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.setState({"alertDialogModal": true, "alertDialogModalMsg": saveDetailsErrors});
    
    axiosHelper.processError(this.isUnmounted, prop, error, (state) => {this.setState(state);}, () => {
      // this.showUsersNotification();
    }, () => {
      // this.props.doLogout({ ...this.props });
    });
  }

  render() {
    const { classes } = this.props;
    return (
        <Dialog
            style={{zIndex: 10000, right: "-35px", left: "-35px"}}
            classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
            open={this.props.cardCreateDialogModal}
            keepMounted
            onClose={()=>this.props.onClose("cardCreateDialogModal")}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle style={{paddingBottom:'0px'}} className={classes.modalBodyBody}>
                {'Update Card Details'}
            </DialogTitle>
            <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
                <div id="PaymentForm" style={{width: "100%"}}>
                  <CreditCard
                    cvc={this.state.cvc}
                    expiry={this.state.expiry}
                    name={this.state.name}
                    number={this.state.number}
                    focused={this.state.focused}
                    callback={this.handleCallback}
                  />
                  <form>
                  <CustomInput
                    success={this.state.number_valid === "success"}
                    error={this.state.number_valid === "error"}
                    labelText="Card Number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helperText={ this.state.number_valid === "error" && this.state.number ? this.state.number_error : undefined }
                    inputProps={{
                      type: "tel",
                      name: "number",
                      onChange: this.handleInputChange,
                      onFocus: this.handleInputFocus
                    }}
                  />
                  <CustomInput
                    success={this.state.name_valid === "success"}
                    error={this.state.name_valid === "error"}
                    labelText="Cardholder name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helperText={ this.state.name_valid === "error" && this.state.name ? this.state.name_error : undefined }
                    inputProps={{
                      type: "text",
                      name: "name",
                      onChange: this.handleInputChange,
                      onFocus: this.handleInputFocus
                    }}
                  />
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8}>
                      <CustomInput
                        success={this.state.expiry_valid === "success"}
                        error={this.state.expiry_valid === "error"}
                        labelText="Expiry MM/YY"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helperText={ this.state.expiry_valid === "error" && this.state.expiry ? this.state.expiry_error : undefined }
                        inputProps={{
                          type: "text",
                          name: "expiry",
                          onChange: this.handleInputChange,
                          onFocus: this.handleInputFocus
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <CustomInput
                        success={this.state.cvc_valid === "success"}
                        error={this.state.cvc_valid === "error"}
                        labelText="CVC"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helperText={ this.state.cvc_valid === "error" && this.state.cvc ? this.state.cvc_error : undefined }
                        inputProps={{
                          type: "text",
                          name: "cvc",
                          onChange: this.handleInputChange,
                          onFocus: this.handleInputFocus,
                          onBlur: this.handleInputBlur
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  </form>
                </div>
                <CustomInput
                  success={this.state.alias_valid === "success"}
                  error={this.state.alias_valid === "error"}
                  labelText="Nickname"
                  id="alias"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={ this.state.alias_valid === "error" && this.state.alias_valid ? this.state.alias_error : undefined }
                  inputProps={{
                    type: "text",
                    onChange: this.handleChange,
                    value: this.state.alias
                  }}
                />
                <FormControlLabel
                  control={
                  <Switch
                      checked={this.state.default}
                      onChange={this.handleSwitchChange("default")}
                      value="default"
                      classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                      }}
                  />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Default"
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button onClick={()=>this.props.onClose("cardCreateDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                    Close
                </Button>
                <Button onClick={this.handleGetCardToken} color="success" simple
                    className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                >
                    Add
                </Button>
            </DialogActions>
            <AlertDialog
              alertDialogModal={this.state.alertDialogModal}
              alertDialogModalMsg={this.state.alertDialogModalMsg}
              onClose={() => this.handleClose("alertDialogModal")}  />
        </Dialog>
    )
  }
}

export default withStyles(cardsStyle)(CardCreateDialog);