const username_rule = /^\w+$/;
// const password_rule = /^.*(?=.{3,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\dX])(?=.*[!@#$%&=?_.,:;*\\-]).*$/;
const email_rule = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const number_rule = /^[0-9]+$/;
const url_rule = /^(http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)+$/;

const validations = {
    validateUsername: function(str) {
        return str && str.length >= 6 && str.length <= 20 && str.match(username_rule);
    },
    validatePassword: function(str) {
        return str && str.length >= 8 && str.length <= 255;// && str.match(password_rule);
    },
    validateEmail: function(str) {
        return str && str.length <= 255 && str.match(email_rule);
    },
    validateIfNumber: function(str) {
        return str && (str + '').match(number_rule);
    },
    validateURL: function(str) {
        return str && str.match(url_rule);
    },
    validateName: function(str) {
        return str && str.length > 0 && str.length <= 255;
    },
    validatePhone: function(str) {
        return str && (str + '').match(number_rule) && str.length === 10;
    },
    validateInputHasValue: function(str) {
        return str && str.length > 0;
    },

    usernameErrorMsg: function() {
        return 'Must be 6 to 20 characters long';
    },
    phoneErrorMsg: function() {
        return 'Phone must be 10 digits';
    },
    passwordErrorMsg: function() {
        return 'Must be at least 8 alphanumeric characters';
    },
    passwordDidNotMatch: function() {
        return 'The password confirmation does not match';
    },
    emailErrorMsg: function() {
        return 'Must be a valid email address';
    },
    requiredErrorMsg: function() {
        return 'This field is required';
    }
}

export default validations;