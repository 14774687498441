import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import helpers from "customs/helpers/helpers";

class TransactionDetailsCard extends React.Component {
    setRecipientName(details) {
        if (details && details.parish && details.parish.name)
            return details.parish.name;
        else if (details && details.event && details.event.name)
            return details.event.name;
        else if (details && details.recipient && details.recipient.name)
            return details.recipient.name;
        else
            return '';
    }

    render() {
        return <Card style={{ marginTop: '20px', marginBottom: '5px' }}>
            <CardBody style={{padding: '10px 5px'}}>
                <table style={{width: '100%', textAlign: "left"}}>
                    <tbody>
                        <tr>
                            <td style={{width: '90%', fontWeight: "bold"}}>
                                {this.setRecipientName(this.props.details)}
                            </td>
                            <td rowSpan={2} style={{width: '1%', fontWeight: "bold", textAlign: "right", color: this.props.details.status === 'declined' ? 'red' : (this.props.details.refunded_at ? 'orange' : 'green')}}>
                                ${helpers.formatMoney(this.props.details.amount / 100, 2, '.', ',')}
                            </td>
                            <td rowSpan={2} style={{width: '1%', fontSize: "15px", textAlign: "right"}}>
                                <i className="fa fa-arrow-right" style={{fontSize: '15px', margin: "0px 03px"}}/>
                            </td>
                        </tr>   
                        <tr>
                            <td style={{width: '5rem', color: this.props.details.refunded_at ? 'orange' : ''}}>
                                {this.props.details.refunded_at ? this.props.details.refunded_at.split(' ')[0] : this.props.details.created_at.split(' ')[0]}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </CardBody>
        </Card>
    }
}

export default TransactionDetailsCard;