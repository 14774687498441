import React from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import axios from 'axios/axiosAuth';

class LocationSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          endpoints: null,
          display: ''
        };
    }

    componentDidMount() {
        axios.get("/app/endpoints")
            .then(response => this.processEndPoints(response))
            .catch(error => '');
    }

    processEndPoints(response) {
        try {
            let display = '';
            let list = [];
            let host = window.location.href.split('//');
            let current = host[0] + '//' + host[1].split('/')[0];
            Object.keys(response.data).forEach(function(key) {
                if (response.data[key]['api_endpoint'].indexOf(current) >= 0) {
                    display = response.data[key]['display'];
                }
                list.push(response.data[key]);
            });
            this.setState({endpoints: list, display: display});
        } catch(e) {}
    }

    handleClick(val) {
        try {
            let endpoints = this.state.endpoints.filter(function (endpoint) {
                return endpoint['display'] === val;
            });
            if (endpoints[0]['display'] !== this.state.display) window.location.replace(endpoints[0]['web_endpoint']);
        } catch(e) {}
    }

    render() {
        return this.state.endpoints
            ? <CustomDropdown
                key={"key"}
                data-ref="multi"
                dropPlacement="bottom"
                hoverColor="info"
                buttonText={this.state.display}
                buttonProps={{
                    simple: true,
                    style: {padding: "0"}
                }}
                dropdownList={this.state.endpoints.map((prop, key) => prop['display'])}
                onClick={(val) => this.handleClick(val)}
            />
            : <div></div>
    }

}

export default LocationSelect;