import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// core components
import Button from "components/CustomButtons/Button.jsx";

class CardUpdateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alias_valid: "",
      id: this.props.cardUpdateDialogModalMsg && this.props.cardUpdateDialogModalMsg.id ? this.props.cardUpdateDialogModalMsg.id : 0,
      alias: this.props.cardUpdateDialogModalMsg && this.props.cardUpdateDialogModalMsg.alias ? this.props.cardUpdateDialogModalMsg.alias : '',
      default: this.props.cardUpdateDialogModalMsg && this.props.cardUpdateDialogModalMsg.default ? this.props.cardUpdateDialogModalMsg.default : false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateCard = this.handleUpdateCard.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleUpdateCard(event) {
    event.preventDefault();
    this.props.handleUpdateCard(this.state.id, this.state.alias ? this.state.alias : "", this.state.default);
}

  render() {
    const { classes } = this.props;
    return (
        <Dialog
            style={{zIndex: 10000, right: "-35px", left: "-35px"}}
            classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
            open={this.props.cardUpdateDialogModal}
            keepMounted
            onClose={()=>this.props.onClose("cardUpdateDialogModal")}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle style={{paddingBottom:'0px'}} className={classes.modalBodyBody}>
                {'Update Card Details'}
            </DialogTitle>
            <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
                <CustomInput
                  success={this.state.alias_valid === "success"}
                  error={this.state.alias_valid === "error"}
                  labelText="Nickname"
                  id="alias"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={ this.state.alias_valid === "error" && this.state.alias_valid ? 'This field is required' : undefined }
                  inputProps={{
                    type: "text",
                    onChange: this.handleChange,
                    value: this.state.alias
                  }}
                />
                <FormControlLabel
                  control={
                  <Switch
                      checked={this.state.default}
                      onChange={this.handleSwitchChange("default")}
                      value="default"
                      classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar
                      }}
                  />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Default"
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button onClick={()=>this.props.onClose("cardUpdateDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                    Close
                </Button>
                <Button onClick={this.handleUpdateCard} color="success" simple
                    className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
  }
}

export default withStyles(cardsStyle)(CardUpdateDialog);