import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AuthService from 'customs/auth/AuthService';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CompanyLogo from "views/Components/CompanyLogo.jsx";
import LocationSelect from "views/Components/LocationSelect.jsx";

import SendResetEmailStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { connect } from 'react-redux';
import { doSendResetEmail } from '../../store/actions/authActions';

class SendResetEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      email_valid: ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleSendResetEmailClick = event => {
    event.preventDefault();
    this.handleSendResetEmail();
  }

  handleSendResetEmail() {
    const is_valid_email = validations.validateEmail(this.state.email);
    if (is_valid_email) {
      helpers.showLoading();
      this.props.doSendResetEmail(this.state.email, { ...this.props });
      this.setState({'email_valid' : ''});
    } else
      this.setState({ "email_valid": is_valid_email ? "success" : "error" });
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') this.handleSendResetEmail();
  }

  handleFormSubmit(event) {
    event.preventDefault();
  }

  handleLoginClick = () => {
    this.props.history.push('/auth/login');
  }

  render() {
    if ((new AuthService(this.state, this.props)).loggedIn())
      return (<div><Redirect to="/admin/dashboard"/></div>);
    if (this.props.auth.message && this.props.auth.has_errors === false)
      return (<div><Redirect to="/auth/login"/></div>)
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="..." style={{width: '5rem', height: '5rem', borderRadius: '10px'}} />
        </GridContainer>
        <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
          <GridItem xs={12} sm={6} md={4}>
            <GridItem md={12} style={{textAlign: "center"}}>
              <img src={process.env.PUBLIC_URL + '/images/logo-main-auth.png'} alt="..." style={{height: '1.8rem', borderRadius: '10px', marginTop: "0.6rem"}} />
            </GridItem>
            <GridItem md={12} style={{textAlign: "center"}}>
              <LocationSelect/>
            </GridItem>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.handleFormSubmit}>
              <Card login className={classes[this.state.cardAnimation]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Password Reset</h4>
                </CardHeader>
                {
                  this.props.auth && this.props.auth.message
                    ? <CardBody style={{paddingBottom:'0px'}}>
                      <h4 className={classes.textCenter} id="reset_password_status" style={{color: this.props.reset_status, marginBottom:'0px', paddingBottom:'0px'}}>
                        {this.props.auth.message}
                      </h4>
                      </CardBody>
                    : ''
                }
                <CardBody style={{paddingTop:'0px', paddingBottom:'0px'}}>
                  <h5 className={classes.socialLine} style={{marginBottom:'0px', paddingBottom:'0px'}}>
                    Please enter your email below and click the Send Reset Email to reset your password.
                  </h5>
                </CardBody>
                <CardBody>
                  <CustomInput
                    success={this.state.email_valid === "success"}
                    error={this.state.email_valid === "error"}
                    labelText="Email"
                    id="email"
                    helperText={ this.state.email_valid === "error" && this.state.email ? 'Invalid email format' : undefined }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      type: "email",
                      onChange: this.handleChange,
                      onKeyDown: this.handleKeyDown
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter} style={{paddingTop:'0px'}}>
                  <Button color="success" simple size="lg" block onClick={(event) => this.handleSendResetEmailClick(event)}>
                    Submit
                  </Button>
                  <Button color="info" simple size="lg" block onClick={this.handleLoginClick}>
                    Log In
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
          <CompanyLogo gridWidth={4}/>
      </div>
    );
  }
}

SendResetEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  reset_status: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  let message = state.auth && state.auth.data && state.auth.data.resetLinkMessage ? state.auth.data.resetLinkMessage : '';
  let has_errors = false;
  if (state.auth && state.auth.data && state.auth.data.errors) {
    let error = {'response': {'data': {'errors': state.auth.data.errors}}};
    message = helpers.getErrorMessage(error);
    has_errors = true;
  }
  state.auth.data['message'] = message;
  state.auth.data['has_errors'] = has_errors;
  return {
    auth: state.auth.data,
    reset_status: state.auth && state.auth.data && state.auth.data.authStatus ? state.auth.data.authStatus : ''
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doSendResetEmail: (email, props) => dispatch(doSendResetEmail(email, props))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(SendResetEmailStyle)(SendResetEmail));
