import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import helpers from "customs/helpers/helpers";
import axios from 'axios/axios';

class ChurchDetailsCard extends React.Component {
    constructor(props) {
        super(props);

        let max_y = 280;
        let max_x = 240;
        try {
            let ratio = 240 / 280;
            max_y = parseInt(12 * parseFloat(getComputedStyle(document.documentElement).fontSize));
            max_x = parseInt(max_y * ratio);
        } catch (e) {
            max_y = 280;
            max_x = 240;
        }

        this.state = {
          id: this.props.details.id,
          logo: null,
          max_y: max_y,
          max_x: max_x,
        };
        this.handleFavouriteClick = this.handleFavouriteClick.bind(this);
        this.handleViewDetailsClick = this.handleViewDetailsClick.bind(this);
    }

    handleFavouriteClick(event) {
        event.preventDefault();
        this.props.onFavouriteClick(this.props.details.id, !this.props.details.favourite);
    }

    handleViewDetailsClick() {
        this.props.handleViewDetailsClick(this.props.details);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidMount() {
        this._ismounted = true;
        this.loadParishImage();
    }

    loadParishImage() {
        if (this.props.details.logo_id)
            this.loadImage(this.props.details.logo_id);
        else
            setTimeout( function() {this.setState({logo: null});}.bind(this), 100 );
    }

    loadImage(id) {
      let uri = this.props.handleGetThumbnailFromList(id);
      if (uri) {
        this.setState({logo: uri});
      } else {
        setTimeout(() => {
            axios(this.props).get("image/parish/" + id + "?max_x=" + this.state.max_x + "&max_y=" + this.state.max_y, {responseType: 'arraybuffer',})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    if (this._ismounted) {
                        this.setState({logo: url});
                        this.props.handleAddThumbnailToList(id, url);
                    }
                })
                .catch((error) => console.log(error));
        }, this.props.delay ? this.props.delay : 200);
      }
    }

    render() {
        if (this.state.id !== this.props.details.id) setTimeout( function() {this.setState({logo: null, id: this.props.details.id}); this.loadParishImage();}.bind(this), 100 );

        return <Card style={{ marginTop: '20px', marginBottom: '5px' }}>
            <CardBody style={{padding: '10px 5px'}}>
                <table style={{width: '100%', textAlign: "left"}}>
                    <tbody>
                        <tr style={{verticalAlign: "top"}}>
                            <td rowSpan={'2'} style={{width: parseInt(this.state.max_x / 2) + 'px', height: parseInt(this.state.max_y / 2) + 'px'}} onClick={this.handleViewDetailsClick}>
                                <img src={this.state.logo ? this.state.logo : (process.env.PUBLIC_URL + '/images/thumb.png')}
                                    alt="..." style={{width: parseInt(this.state.max_x / 2) + 'px', borderRadius: '5px'}} />
                            </td>
                            <td style={{width: '0.5rem'}} onClick={this.handleViewDetailsClick}>
                                <i className="fa fa-church" style={{fontSize: '15px', margin: "0px 03px"}}/>
                            </td>
                            <td style={{fontSize: "15px", fontWeight: "bold"}} onClick={this.handleViewDetailsClick}>
                                <div className="trunc-container">
                                    <div className="trunc-content">{this.props.details.name}</div>
                                    <div className="trunc-spacer">{this.props.details.name}</div>
                                    <span>&nbsp;</span>
                                </div>
                            </td>
                            <td rowSpan={'2'} style={{width: '0.5rem', verticalAlign: 'bottom'}}>
                                <i onClick={(event) => this.handleFavouriteClick(event)} className={(this.props.details.favourite === 1 ? 'fas' : 'far') + ' fa-heart'} style={{fontSize: '20px', color: "#D75A4A"}}/>
                            </td>
                        </tr>
                        <tr onClick={this.handleViewDetailsClick}>
                            <td>
                                <i className="fa fa-map-marked" style={{fontSize: '15px', margin: "0px 03px"}}/>
                            </td>
                            <td style={{fontSize: "13px"}}>
                                {
                                    this.props.details.distance
                                    ? 'Distance: ' + helpers.formatMoney(this.props.details.distance / 1000, 0, '.', ',') + ' km'
                                    : helpers.addLineBreaks(
                                        this.props.details.address1 + (this.props.details.address2 ? " " + this.props.details.address2 : "") + ",<br>" +
                                        this.props.details.city + ", " + this.props.details.state + "<br>" + this.props.details.country + " - " + this.props.details.postal_code
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </CardBody>
        </Card>
    }
}

export default ChurchDetailsCard;