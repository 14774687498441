import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AuthService from 'customs/auth/AuthService';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CompanyLogo from "views/Components/CompanyLogo.jsx";
import LocationSelect from "views/Components/LocationSelect.jsx";

import RegisterPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { connect } from 'react-redux';
import { doSendRegistration } from '../../store/actions/authActions';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      full_name_valid: "",
      phone_valid: "",
      address_one_valid: "",
      address_two_valid: "",
      city_valid: "",
      state_valid: "",
      postcode_valid: "",
      country_valid: "",
      email_valid: "",
      password_valid: "",
      confirm_password_valid: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleLoginClick = event => {
    this.props.history.push('/auth/login');
  }

  handleRegisterClick = event => {
    event.preventDefault();
    this.handleRegister();
  }

  handleRegister() {
    let cont = true;
    let params = {};

    const is_valid_full_name = validations.validateName(this.state.full_name);
    const is_valid_phone = validations.validatePhone(this.state.phone);
    const is_valid_address_one = validations.validateInputHasValue(this.state.address_one);
    const is_valid_address_two = validations.validateInputHasValue(this.state.address_two);
    const is_valid_city = validations.validateInputHasValue(this.state.city);
    const is_valid_state = validations.validateInputHasValue(this.state.state);
    const is_valid_postcode = validations.validateInputHasValue(this.state.postcode);
    const is_valid_country = validations.validateInputHasValue(this.state.country);

    const is_valid_email = validations.validateEmail(this.state.email);
    const is_valid_password = validations.validatePassword(this.state.password);
    const is_valid_confirm_password = validations.validatePassword(this.state.confirm_password);

    if (!is_valid_full_name) {
      cont = false;
      this.setState({ "full_name_valid": "error" });
    } else {
      this.setState({"full_name_valid" : ""});
      params['name'] = this.state.full_name;
    }
    if (!is_valid_phone) {
      cont = false;
      this.setState({ "phone_valid": "error", "phone_error": validations.phoneErrorMsg() });
    } else {
      this.setState({"phone_valid" : "", "phone_error": ""});
      params['phone'] = this.state.phone;
    }

    if ((is_valid_address_one || is_valid_city || is_valid_state || is_valid_postcode || is_valid_country)
          && !(is_valid_address_one && is_valid_city && is_valid_state && is_valid_postcode && is_valid_country)) {
      cont = false;
      
      if (!is_valid_address_one) this.setState({ "address_one_valid": "error", "address_one_error": validations.requiredErrorMsg() });
      else this.setState({ "address_one_valid": "", "address_one_error": "" });

      if (is_valid_address_two) params['address2'] = this.state.address2;

      if (!is_valid_city) this.setState({ "city_valid": "error", "city_error": validations.requiredErrorMsg() });
      else this.setState({ "city_valid": "", "city_error": "" });

      if (!is_valid_state) this.setState({ "state_valid": "error", "state_error": validations.requiredErrorMsg() });
      else this.setState({ "state_valid": "", "state_error": "" });

      if (!is_valid_postcode) this.setState({ "postcode_valid": "error", "postcode_error": validations.requiredErrorMsg() });
      else this.setState({ "postcode_valid": "", "postcode_error": "" });

      if (!is_valid_country) this.setState({ "country_valid": "error", "country_error": validations.requiredErrorMsg() });
      else this.setState({ "country_valid": "", "country_error": "" });
    } else {
      if (is_valid_address_one) params['address1'] = this.state.address_one;
      if (is_valid_address_two) params['address2'] = this.state.address_two;
      if (is_valid_city) params['city'] = this.state.city;
      if (is_valid_state) params['state'] = this.state.state;
      if (is_valid_postcode) params['postal_code'] = this.state.postcode;
      if (is_valid_country) params['country'] = this.state.country;
    }

    if (!is_valid_email) {
      cont = false;
      this.setState({ "email_valid": "error", "email_error": validations.emailErrorMsg() });
    } else {
      this.setState({"email_valid" : "", "email_error": ""});
      params['email'] = this.state.email;
    }
    if (!is_valid_password) {
      cont = false;
      this.setState({ "password_valid": "error", "password_error": validations.passwordErrorMsg() });
    } else {
      this.setState({"password_valid" : "", "password_error": ""});
      params['password'] = this.state.password;
    }
    if (!is_valid_confirm_password) {
      cont = false;
      this.setState({ "confirm_password_valid": "error", "confirm_password_error": validations.passwordErrorMsg() });
    } else {
      this.setState({"confirm_password_valid" : "", "confirm_password_error": ""});
      params['password_confirmation'] = this.state.confirm_password;
    }
    if (is_valid_password && is_valid_confirm_password && this.state.password !== this.state.confirm_password) {
      cont = false;
      this.setState({ "confirm_password_valid": "error", "confirm_password_error": validations.passwordDidNotMatch() });
    }

    if (cont) {
      helpers.showLoading();
      this.props.doSendRegistration(params, { ...this.props });
    }
  }

  render() {
    if ((new AuthService(this.state, this.props)).loggedIn())
      return (<div><Redirect to="/admin/parishes"/></div>)
    else if (this.props.error_msg === 'User Registered')
      return (<div><Redirect to="/auth/login"/></div>)
    else {
      const { classes, auth } = this.props;

      let full_name_valid = this.state.full_name_valid;
      let full_name_error = this.state.full_name_valid === "error" && this.state.full_name ? 'Full Name is invalid' : undefined;
      let phone_valid = this.state.phone_valid;
      let phone_error = this.state.phone_valid === "error" && this.state.phone && this.state.phone_error ? this.state.phone_error : undefined;
      let address_one_valid = this.state.address_one_valid;
      let address_one_error = this.state.address_one_valid === "error" && this.state.address_one_error ? this.state.address_one_error : undefined;
      let address_two_valid = this.state.address_two_valid;
      let address_two_error = this.state.address_two_valid === "error" && this.state.address_two ? 'Address Line 2 is invalid' : undefined;
      let city_valid = this.state.city_valid;
      let city_error = this.state.city_valid === "error" && this.state.city_error ? this.state.city_error : undefined;
      let state_valid = this.state.state_valid;
      let state_error = this.state.state_valid === "error" && this.state.state_error ? this.state.city_error : undefined;
      let postcode_valid = this.state.postcode_valid;
      let postcode_error = this.state.postcode_valid === "error" && this.state.postcode_error ? this.state.postcode_error : undefined;
      let country_valid = this.state.country_valid;
      let country_error = this.state.country_valid === "error" && this.state.country_error ? this.state.country_error : undefined;
      let email_valid = this.state.email_valid;
      let email_error = this.state.email_valid === "error" && this.state.email && this.state.email_error ? this.state.email_error : undefined;
      let password_valid = this.state.password_valid;
      let password_error = this.state.password_valid === "error" && this.state.password && this.state.password_error ? this.state.password_error : undefined;
      let confirm_password_valid = this.state.confirm_password_valid;
      let confirm_password_error = this.state.confirm_password_valid === "error" && this.state.confirm_password && this.state.confirm_password_error ? this.state.confirm_password_error : undefined;
      if (auth.errors) {
        full_name_valid = auth.errors.name && auth.errors.name.length > 0 ? "error" : "";
        full_name_error = auth.errors.name && auth.errors.name.length > 0 ? auth.errors.name.join(' ') : "";
        phone_valid = auth.errors.phone && auth.errors.phone.length > 0 ? "error" : "";
        phone_error = auth.errors.phone && auth.errors.phone.length > 0 ? auth.errors.phone.join(' ') : "";
        address_one_valid = auth.errors.address1 && auth.errors.address1.length > 0 ? "error" : "";
        address_one_error = auth.errors.address1 && auth.errors.address1.length > 0 ? auth.errors.address1.join(' ') : "";
        address_two_valid = auth.errors.address2 && auth.errors.address2.length > 0 ? "error" : "";
        address_two_error = auth.errors.address2 && auth.errors.address2.length > 0 ? auth.errors.address2.join(' ') : "";
        city_valid = auth.errors.city && auth.errors.city.length > 0 ? "error" : "";
        city_error = auth.errors.city && auth.errors.city.length > 0 ? auth.errors.city.join(' ') : "";
        state_valid = auth.errors.state && auth.errors.state.length > 0 ? "error" : "";
        state_error = auth.errors.state && auth.errors.state.length > 0 ? auth.errors.state.join(' ') : "";
        postcode_valid = auth.errors.postal_code && auth.errors.postal_code.length > 0 ? "error" : "";
        postcode_error = auth.errors.postal_code && auth.errors.postal_code.length > 0 ? auth.errors.postal_code.join(' ') : "";
        country_valid = auth.errors.country && auth.errors.country.length > 0 ? "error" : "";
        country_error = auth.errors.country && auth.errors.country.length > 0 ? auth.errors.country.join(' ') : "";
        email_valid = auth.errors.email && auth.errors.email.length > 0 ? "error" : "";
        email_error = auth.errors.email && auth.errors.email.length > 0 ? auth.errors.email.join(' ') : "";
        password_valid = auth.errors.password && auth.errors.password.length > 0 ? "error" : "";
        password_error = auth.errors.password && auth.errors.password.length > 0 ? auth.errors.password.join(' ') : "";
        confirm_password_valid = auth.errors.password_confirmation && auth.errors.password_confirmation.length > 0 ? "error" : "";
        confirm_password_error = auth.errors.password_confirmation && auth.errors.password_confirmation.length > 0 ? auth.errors.password_confirmation.join(' ') : "";
      }

      return (
        <div className={classes.container}>
          <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="..." style={{width: '5rem', height: '5rem', borderRadius: '10px'}} />
          </GridContainer>
          <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
            <GridItem xs={12} sm={6} md={4}>
              <GridItem md={12} style={{textAlign: "center"}}>
                <img src={process.env.PUBLIC_URL + '/images/logo-main-auth.png'} alt="..." style={{height: '1.8rem', borderRadius: '10px', marginTop: "0.6rem"}} />
              </GridItem>
              <GridItem md={12} style={{textAlign: "center"}}>
                <LocationSelect/>
              </GridItem>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={5}>
              <form>
                <Card login className={classes[this.state.cardAnimation]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Register</h4>
                  </CardHeader>
                  {
                    this.props.error_msg
                      ? <CardBody style={{paddingBottom:'0px'}}>
                        <h4 className={classes.textCenter} id="reset_password_status" style={{color: this.props.error_msg === 'User Registered' ? '#4caf50' : 'red', marginBottom:'0px', paddingBottom:'0px'}}>
                          {this.props.error_msg}
                        </h4>
                        </CardBody>
                      : ''
                  }
                  <CardBody>
                  <CustomInput
                      success={full_name_valid === "success"}
                      error={full_name_valid === "error"}
                      labelText="Full Name *"
                      id="full_name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ full_name_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={phone_valid === "success"}
                      error={phone_valid === "error"}
                      labelText="Phone *"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ phone_error }
                      inputProps={{
                        maxLength: "10",
                        type: "number",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={address_one_valid === "success"}
                      error={address_one_valid === "error"}
                      labelText="Address Line 1"
                      id="address_one"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ address_one_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={address_two_valid === "success"}
                      error={address_two_valid === "error"}
                      labelText="Address Line 2"
                      id="address_two"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ address_two_error }
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={city_valid === "success"}
                          error={city_valid === "error"}
                          labelText="City"
                          id="city"
                          formControlProps={{
                            fullWidth: true
                          }}
                          helperText={ city_error }
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            onKeyDown: this.handleKeyDown
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={state_valid === "success"}
                          error={state_valid === "error"}
                          labelText="State"
                          id="state"
                          formControlProps={{
                            fullWidth: true
                          }}
                          helperText={ state_error }
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            onKeyDown: this.handleKeyDown
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={postcode_valid === "success"}
                          error={postcode_valid === "error"}
                          labelText="Post Code"
                          id="postcode"
                          formControlProps={{
                            fullWidth: true
                          }}
                          helperText={ postcode_error }
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            onKeyDown: this.handleKeyDown
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={country_valid === "success"}
                          error={country_valid === "error"}
                          labelText="Country"
                          id="country"
                          formControlProps={{
                            fullWidth: true
                          }}
                          helperText={ country_error }
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            onKeyDown: this.handleKeyDown
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      success={email_valid === "success"}
                      error={email_valid === "error"}
                      labelText="Email *"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ email_error }
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        type: "email",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={password_valid === "success"}
                      error={password_valid === "error"}
                      labelText="Password *"
                      id="password"
                      helperText={ password_error }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={confirm_password_valid === "success"}
                      error={confirm_password_valid === "error"}
                      labelText="Confirm Password *"
                      id="confirm_password"
                      helperText={ confirm_password_error }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter} style={{paddingTop:'0px'}}>
                    <Button color="success" simple size="lg" block onClick={(event) => this.handleRegisterClick(event)}>
                      Register
                    </Button>
                    <Button color="info" simple size="lg" block onClick={this.handleLoginClick}>
                      Log In
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
          <CompanyLogo gridWidth={5}/>
        </div>
      );
    }
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  error_msg: PropTypes.string
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    error_msg: state.auth && state.auth.data && state.auth.data.message ? state.auth.data.message : '',
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doSendRegistration: (params, props) => dispatch(doSendRegistration(params, props))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(RegisterPageStyle)(RegisterPage));
