import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AuthService from 'customs/auth/AuthService';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CompanyLogo from "views/Components/CompanyLogo.jsx";
import LocationSelect from "views/Components/LocationSelect.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { connect } from 'react-redux';
import { doLogin } from '../../store/actions/authActions';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      email_valid: "",
      password_valid: ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleLoginClick = event => {
    event.preventDefault();
    this.handleLogin();
  }

  handleLogin() {
    const is_valid_email = validations.validateEmail(this.state.email);
    const is_valid_password = validations.validatePassword(this.state.password);
    if (is_valid_email && is_valid_password) {
      helpers.showLoading();
      this.props.doLogin(this.state.email, this.state.password, { ...this.props });
      this.setState({'email_valid' : '', 'password_valid' : ''});
    } else
      this.setState({ "email_valid": is_valid_email ? "success" : "error", "password_valid": is_valid_password ? "success" : "error" });
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') this.handleLogin();
  }

  handleSendResetEmailClick = () => {
    this.props.history.push('/auth/password/reset');
  }

  handleRegisterClick = () => {
    this.props.history.push('/auth/register');
  }

  handleSetupClick = () => {
    window.location.href = process.env.PUBLIC_URL + '/slides/';
  }

  render() {
    if ((new AuthService(this.state, this.props)).loggedIn())
      return (<div><Redirect to="/admin/parishes"/></div>)
    else {
      const { classes } = this.props;

      const updateMessage = this.props.auth && this.props.auth.message && this.props.auth.message.length > 0 ? this.props.auth.message : '';
      const authStatus = this.props.auth && this.props.auth.authStatus ? this.props.auth.authStatus : 'red';
      if (updateMessage) {
        this.props.auth.message = '';
        this.props.auth.resetLinkMessage = '';
        setTimeout( function() {
          this.setState({updateMessage, authStatus});
        }.bind(this), 100 );
      }

      return (
        <div className={classes.container}>
          <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="..." style={{width: '5rem', height: '5rem', borderRadius: '10px'}} />
          </GridContainer>
          <GridContainer justify="center" className={classes[this.state.cardAnimation]}>
            <GridItem xs={12} sm={6} md={4}>
              <GridItem md={12} style={{textAlign: "center"}}>
                <img src={process.env.PUBLIC_URL + '/images/logo-main-auth.png'} alt="..." style={{height: '1.8rem', borderRadius: '10px', marginTop: "0.6rem"}} />
              </GridItem>
              <GridItem md={12} style={{textAlign: "center"}}>
                <LocationSelect/>
              </GridItem>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[this.state.cardAnimation]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                  </CardHeader>
                  {
                    this.props.error_msg
                      ? <CardBody style={{paddingBottom:'0px'}}>
                        <h4 className={classes.textCenter} id="reset_password_status" style={{color: 'red', marginBottom:'0px', paddingBottom:'0px'}}>
                          {this.props.error_msg}
                        </h4>
                        </CardBody>
                      : (
                        this.state.updateMessage && this.state.updateMessage.length > 0
                          ? <CardBody style={{paddingBottom:'0px'}}>
                            <h4 className={classes.textCenter} style={{color: this.state.authStatus, marginBottom:'0px', paddingBottom:'0px'}}>
                              {this.state.updateMessage}
                            </h4>
                            </CardBody>
                          : ''
                      )
                  }
                  <CardBody>
                    <CustomInput
                      success={this.state.email_valid === "success"}
                      error={this.state.email_valid === "error"}
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ this.state.email_valid === "error" && this.state.email ? 'Invalid email format' : undefined }
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        type: "email",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                    <CustomInput
                      success={this.state.password_valid === "success"}
                      error={this.state.password_valid === "error"}
                      labelText="Password"
                      id="password"
                      helperText={ this.state.password_valid === "error" && this.state.password ?
                        'Invalid password format' : undefined }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter} style={{paddingTop:'0px'}}>
                    <Button color="success" simple size="lg" block onClick={(event) => this.handleLoginClick(event)}>
                      Login
                    </Button>
                    <Button color="info" simple size="lg" block onClick={this.handleSendResetEmailClick}>
                      Forgot Password?
                    </Button>
                  </CardFooter>

                  
                  <Button style={{color: '#797979', paddingTop: "5px", paddingBottom: "5px", marginBottom: "0"}} simple size="lg" block onClick={this.handleRegisterClick}>
                      New user? Create Account
                  </Button>
                  <Button style={{color: '#797979', paddingTop: "5px", paddingBottom: "5px", marginBottom: "1.25rem"}} simple size="lg" block onClick={this.handleSetupClick}>
                  Use the Mobile App (iPhone/Android)
                  </Button>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
          <CompanyLogo gridWidth={4}/>
        </div>
      );
    }
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  error_msg: PropTypes.string
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    error_msg: state.auth && state.auth.data && state.auth.data.authMessage ? state.auth.data.authMessage : ''
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (email, password, props) => dispatch(doLogin(email, password, props))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));
