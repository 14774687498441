import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import givingHistoryStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TransactionDetailsCard from "views/Components/TransactionDetailsCard.jsx";
import ScheduleDetailsCard from "views/Components/ScheduleDetailsCard.jsx";
import TransactionDetailsDialog from "views/Dashboard/Dialogs/TransactionDetailsDialog.jsx";
import ScheduleDetailsDialog from "views/Dashboard/Dialogs/ScheduleDetailsDialog.jsx";
import AlertDialog from "views/Dashboard/Dialogs/AlertDialog.jsx";
import helpers from "customs/helpers/helpers";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { doFetchTransactions, doFetchSchedules, doFetchCards } from '../../store/actions/parishActions';

import { connect } from 'react-redux';
import AuthService from 'customs/auth/AuthService';
import axios from 'axios/axios';

class GivingHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: {},
      schedules: {},
      transactionDetailsModal: false,
      transactionDetailsModalMsg: null,
      alertDialogModal: false,
      alertDialogModalMsg: '',
      scheduleDetailsDialogModal: false,
      scheduleDetailsDialogDetails: {},
      tr: false,
      tr_color: "success",
      doneModalMsg: ''
    };
    this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this);
    this.handleChildError = this.handleChildError.bind(this);
  }

  handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
  }

  componentDidMount () {
    helpers.showLoading();
    this.props.doFetchTransactions({ ...this.props });
    setTimeout(()=> {this.props.doFetchSchedules({ ...this.props });}, 500);
    setTimeout(()=> {this.props.doFetchCards({ ...this.props });}, 1000);
  }

  handleFetchTransaction(id) {
    helpers.showLoading();
    axios(this.props).get("/txn/" + id).then(response => this.processFetchTransaction(response)).catch(error => this.processErrorAxios(error, null, "An error has occurred while retrieving your transaction, please try again."));
  }

  processFetchTransaction(response) {
    helpers.hideLoading();
    this.setState({"transactionDetailsModal": true, "transactionDetailsModalMsg": response.data});
  }

  processErrorAxios(error, prop, err_msg) {
    helpers.hideLoading();
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.handleChildError(saveDetailsErrors, 'danger');
  }

  showScheduleDetails(schedule) {
    this.setState({'scheduleDetailsDialogModal': true, 'scheduleDetailsDialogDetails': schedule});
  }

  handleUpdateSchedule(schedule, action) {
    this.props.doFetchSchedules({ ...this.props });
    this.setState({'scheduleDetailsDialogDetails': schedule});

    this.setState({ doneModalMsg: 'Schedule ' + action, tr_color: "success" });
    this.showUsersNotification();
  }

  hideNotification;
  showUsersNotification() {
    if (!this.state.tr)
      this.setState({tr: true});
    else
      clearTimeout(this.hideNotification);
    this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout() {
    this.hideNotification = setTimeout(
      function() {
        this.handleCloseNotification();
      }.bind(this),
      5000
    );
  }

  handleCloseNotification() {
    clearTimeout(this.hideNotification);
    this.setState({tr: false, doneModalMsg: ''});
  }

  handleChildError(title, color) {
    this.setState({ doneModalMsg: title, tr_color: color });
    this.showUsersNotification();
  }

  showAttemptsError() {
    setTimeout(
      function() {
        let error = this.props.auth.attempts_error;
        if (this.props.auth && this.props.auth.attempts_error)
          this.handleChildError(error, 'danger');
        this.props.auth.attempts_error = '';
      }.bind(this),
      100
    )
  }

  render() {
    const authService = new AuthService(this.state, this.props);
    if (!authService.getToken())
      return (<div><Redirect to="/auth/login"/></div>);

    this.showAttemptsError();

    const btnStyle = {background: "transparent", width: "100%", padding: "0px", outline: "0", margin: "0px", border: "0"};
      
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardBody>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Gifts",
                      tabContent: (
                        <div>
                        {
                          this.props.transactions && this.props.transactions.length > 0
                          ? this.props.transactions.map((prop, key) => {
                                return (
                                  <GridItem xs={12} sm={12} key={key}>
                                    <button style={btnStyle} onClick={() => this.handleFetchTransaction(prop.id)}>
                                      <TransactionDetailsCard details={prop}/>
                                    </button>
                                  </GridItem>
                                );
                            })
                          : ''
                        }
                        </div>
                      )
                    },
                    {
                      tabButton: "Recurring Giving",
                      tabContent: (
                        <div>
                        {
                          this.props.schedules && this.props.schedules.length > 0
                          ? this.props.schedules.map((prop, key) => {
                                return (
                                  <GridItem xs={12} sm={12} key={key}>
                                    <button style={btnStyle} onClick={() => this.showScheduleDetails(prop)}>
                                      <ScheduleDetailsCard details={prop}/>
                                    </button>
                                  </GridItem>
                                );
                            })
                          : ''
                        }
                        </div>
                      )
                    },
                  ]}
                />
              </CardBody>
            </Card>
            {
              this.state.scheduleDetailsDialogModal
              ? <ScheduleDetailsDialog
                  details={this.state.scheduleDetailsDialogDetails}
                  scheduleDetailsDialogModal={this.state.scheduleDetailsDialogModal}
                  paymentMethods={this.props.cards}
                  handleUpdateSchedule={this.handleUpdateSchedule}
                  type={"event"}
                  onClose={() => this.handleClose("scheduleDetailsDialogModal")}
                  auth={this.props.auth}
                  />
              : ""
            }
            <TransactionDetailsDialog
              transactionDetailsModal={this.state.transactionDetailsModal}
              transactionDetailsModalMsg={this.state.transactionDetailsModalMsg}
              onClose={() => this.handleClose("transactionDetailsModal")}  />
            <AlertDialog
              alertDialogModal={this.state.alertDialogModal}
              alertDialogModalMsg={this.state.alertDialogModalMsg}
              onClose={() => this.handleClose("alertDialogModal")}  />
          </GridItem>
          {
            this.state.doneModalMsg
            ? <Snackbar
                place="tr"
                color={this.state.tr_color}
                icon={AddAlert}
                message={this.state.doneModalMsg}
                open={this.state.tr}
                closeNotification={() => {this.handleCloseNotification();}}
                close
            />
            : ''
          }
        </GridContainer>
      </div>
    );
  }
}

GivingHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    transactions: state.auth.transactions,
    schedules: state.auth.schedules,
    cards: state.auth.cards,
    force_render: Math.random()
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchTransactions: (props) => dispatch(doFetchTransactions(props)),
    doFetchSchedules: (props) => dispatch(doFetchSchedules(props)),
    doFetchCards: (props) => dispatch(doFetchCards(props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(givingHistoryStyle)(GivingHistory));
