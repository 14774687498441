import axios from 'axios/axiosAuth';
import axiosWithToken from 'axios/axios';
import * as authActionTypes from './authActionTypes';
import { http_post } from '../httpPostClientAction';
import { http_get } from '../httpGetClientAction';

export const doLogin = (email, password, props) => {
    return (dispatch, getState) => {
        callLogin(email, password, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callLogin(email, password, callback) {
    http_post(axios, '/auth/login', {email, password}, authActionTypes.LOGIN_SUCCESS, authActionTypes.LOGIN_ERROR, callback);
}

export const doTokenLogin = (token, props) => {
    return (dispatch, getState) => {
        callTokenLogin(token, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callTokenLogin(token, callback) {
    http_post(axios, '/auth/login', {link_token: token}, authActionTypes.LOGIN_SUCCESS, authActionTypes.TOKEN_LOGIN_ERROR, callback);
}

export const doFetchUserDetails = (props) => {
    return (dispatch, getState) => {
        callFetchUserDetails(props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callFetchUserDetails(props, callback) {
    http_get(axiosWithToken(props), '/auth/me', authActionTypes.FETCH_ME_SUCCESS, authActionTypes.FETCH_ME_ERROR, callback);
}

export const doLogout = (props) => {
    return (dispatch, getState) => {
        dispatch({type: authActionTypes.DO_LOGOUT, props});
    }
};

export const doUpdateProfile = (params, props) => {
    return (dispatch, getState) => {
        callUpdateProfile(params, props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callUpdateProfile(params, props, callback) {
    http_post(axiosWithToken(props), '/auth/me', params, authActionTypes.UPDATE_PROFILE_SUCCESS, authActionTypes.UPDATE_PROFILE_ERROR, callback);
}

export const doChangePassword = (params, props) => {
    return (dispatch, getState) => {
        callChangePassword(params, props, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callChangePassword(params, props, callback) {
    http_post(axiosWithToken(props), '/change_password', params, authActionTypes.CHANGE_PASSWORD_SUCCESS, authActionTypes.CHANGE_PASSWORD_ERROR, callback);
}

export const doSendResetEmail = (email, props) => {
    return (dispatch, getState) => {
        callSendResetEmail(email, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callSendResetEmail(email, callback) {
    http_post(axios, '/password/reset', {email}, authActionTypes.SEND_RESET_EMAIL_SUCCESS, authActionTypes.SEND_RESET_EMAIL_ERROR, callback);
}

export const doResetPassword = (token, params, props) => {
    return (dispatch, getState) => {
        callDoResetPassword(token, params, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callDoResetPassword(token, params, callback) {
    http_post(axios, '/reset/password/' + token, params, authActionTypes.RESET_PASSWORD_SUCCESS, authActionTypes.RESET_PASSWORD_ERROR, callback);
}

export const doUpdateProfileData = (params, props) => {
    const auth = props.auth;
    auth.user = params.user;
    return dispatch => dispatch({'type' : authActionTypes.UPDATE_PROFILE_DATA, 'data' : {response : {data : auth}}});
}

export const doSendRegistration = (params, props) => {
    return (dispatch, getState) => {
        callSendRegistration(params, response => {
            dispatch({type: response.type, response, props});
        });
    }
};

function callSendRegistration(params, callback) {
    http_post(axios, '/auth/register', params, authActionTypes.REGISTRATION_SUCCESS, authActionTypes.REGISTRATION_ERROR, callback);
}

export const doRefreshToken = (response, props) => {
    return (dispatch, getState) => {
        dispatch({type: authActionTypes.DO_REFRESH_TOKEN, response, props});
    }
};