import decode from 'jwt-decode';

export default class AuthService {
    constructor(state, props) {
        this.state = state;
        this.props = props;
    }

    getToken() {
        var auth_token = '';
        try {
            if (this.state && this.state.auth && this.state.auth.data && this.state.auth.data.auth_token)
                auth_token = this.state.auth.data.auth_token;
            else if (this.props && this.props.auth && this.props.auth.auth_token)
                auth_token = this.props.auth.auth_token;
        } catch(e) {}
        return auth_token;
    }

    loggedIn() {
        const auth_token = this.getToken();
        return !!auth_token && !this.isTokenExpired(auth_token);
    }

    isTokenExpired(auth_token) {
        try {
            const decoded = decode(auth_token);
            return (decoded.exp < Date.now() / 1000);
        } catch (err) {}
        return false;
    }

    isPasswordExpired() {
        const password_expires = (this.state.auth && this.state.auth.data && this.state.auth.data.user && this.state.auth.data.user.expires)
            ? this.state.auth.data.user.expires * 1000
            : (this.props.auth && this.props.auth.user && this.props.auth.user.expires
                ? this.props.auth.user.expires * 1000
                : ''
              );
        return password_expires ? password_expires < Date.now() : true;
    }

    getUserId() {
        return this.props.auth && this.props.auth.user && this.props.auth.user.id ? this.props.auth.user.id : '';
    }
}