import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import parishesStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ChurchDetailsCard from "views/Components/ChurchDetailsCard.jsx";
import SearchDialog from "views/Dashboard/Dialogs/SearchDialog.jsx";
import GiftDetailsDialog from "views/Dashboard/Dialogs/GiftDetailsDialog.jsx";
import helpers from "customs/helpers/helpers";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { doFetchParishes, doFetchCards } from '../../store/actions/parishActions';

import { connect } from 'react-redux';
import AuthService from 'customs/auth/AuthService';
import axios from 'axios/axios';
import { geolocated } from "react-geolocated";

class Parishes extends React.Component {
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.state = {
      parishes: {},
      show_favourites: false,
      search_term: "",
      find_nearest_parish: false,
      searchDialogModal: false,
      giftDetailsDialogModal: false,
      giftDetailsDialogModalDetails: {},
      giftDetailsDialogSelectedId: 0,
      thumbnails: [],
      covers: [],
      p: params.get('p') ? params.get('p') : null
    };

    this.handleViewFavourites = this.handleViewFavourites.bind(this);
    this.handleFavouriteClick = this.handleFavouriteClick.bind(this);
    this.handleSearchParishClick = this.handleSearchParishClick.bind(this);
    this.handleStartSearch = this.handleStartSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleViewDetailsClick = this.handleViewDetailsClick.bind(this);
    this.handleChildError = this.handleChildError.bind(this);
    this.handleFindNearParishClick = this.handleFindNearParishClick.bind(this);
    this.handleAddThumbnailToList = this.handleAddThumbnailToList.bind(this);
    this.handleGetThumbnailFromList = this.handleGetThumbnailFromList.bind(this);
    this.handleAddCoverToList = this.handleAddCoverToList.bind(this);
    this.handleGetCoverFromList = this.handleGetCoverFromList.bind(this);
    this.handleAutoOpenParish = this.handleAutoOpenParish.bind(this);
  }

  handleAddThumbnailToList(id, uri) {
    if (this.handleGetThumbnailFromList(id) === null) {
      let thumbnails = this.state.thumbnails;
      thumbnails.push({'id': id, 'uri': uri});
      this.setState({thumbnails});
    }
  }

  handleGetThumbnailFromList(id) {
    let thumbnails = this.state.thumbnails;
    if (thumbnails.length === 0) return null;

    let thumbnail = null;
    for(let i=0; i<thumbnails.length; i++) {
      if (thumbnails[i]['id'] === id) {
        thumbnail = thumbnails[i];
        break;
      } 
    }
    return thumbnail && thumbnail['uri'] ? thumbnail['uri'] : null;
  }

  handleAddCoverToList(id, uri) {
    if (this.handleGetCoverFromList(id) === null) {
      let covers = this.state.covers;
      covers.push({'id': id, 'uri': uri});
      this.setState({covers});
    }
  }

  handleGetCoverFromList(id) {
    let covers = this.state.covers;
    if (covers.length === 0) return null;

    let cover = null;
    for(let i=0; i<covers.length; i++) {
      if (covers[i]['id'] === id) {
        cover = covers[i];
        break;
      } 
    }
    return cover && cover['uri'] ? cover['uri'] : null;
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  componentWillUnmount() {
      this._ismounted = false;
  }

  componentDidMount () {
    this._ismounted = true;
    this.getAllParishes();
    setTimeout(()=> {this.props.doFetchCards({ ...this.props });}, 500);
  }

  getAllParishes() {
    helpers.showLoading();
    this.props.doFetchParishes('', '', '', '', { ...this.props });
  }

  handleViewFavourites() {
    //Remove if not needed to request from server
    helpers.showLoading();
    this.props.doFetchParishes(this.state.search_term, '', '', !this.state.show_favourites ? '1' : '', { ...this.props });
    //Remove if not needed to request from server

    this.setState({show_favourites: !this.state.show_favourites});
  }

  handleFavouriteClick(id, is_favourite) {
    helpers.showLoading();
    axios(this.props).get("parish/" + id + "/" + (is_favourite ? "favourite" : "unfavourite"))
      .then(response => this.processFavourite(response, is_favourite))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processFavourite(response, is_favourite) {
    if (this.state.giftDetailsDialogModal) {
      this.handleViewDetailsClick(this.state.giftDetailsDialogModalDetails);
    }
    if (this.state.find_nearest_parish)
      this.props.doFetchParishes(this.state.search_term, this.props.coords.latitude, this.props.coords.longitude, this.state.show_favourites ? '1' : '', { ...this.props });
    else
      this.props.doFetchParishes(this.state.search_term, '', '', this.state.show_favourites ? '1' : '', { ...this.props });
  }

  processErrorAxios(error, prop, err_msg) {
    if (!this._ismounted) return;
    helpers.hideLoading();
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.handleChildError(saveDetailsErrors, 'danger');
  }

  handleStartSearch(search_term) {
    this.setState({searchDialogModal: false, search_term: search_term});
    helpers.showLoading();
    this.props.doFetchParishes(search_term, '', '', '', { ...this.props });
  }

  handleSearchParishClick() {
    this.setState({searchDialogModal: true});
  }

  handleClearSearch() {
    this.setState({searchDialogModal: false, search_term: '', find_nearest_parish: false});
    this.getAllParishes();
  }

  handleFindNearParishClick() {
    if (!this.props.isGeolocationEnabled) {
      this.setState({ doneModalMsg: 'Access to location is currently not available, please try later or unblock from your browser\'s settings page', tr_color: 'danger' });
      this.showUsersNotification();
    } else {
      this.setState({find_nearest_parish: true});
      helpers.showLoading();
      this.props.doFetchParishes('', this.props.coords.latitude, this.props.coords.longitude, '', { ...this.props });
    }
  }

  handleViewDetailsClick(details) {
    helpers.showLoading();
    axios(this.props).get("parish/" + details.id)
      .then((response) => {
        helpers.hideLoading();
        let selected = 0;
        if (response.data && response.data.images) {
          for(let i=0; i<response.data.images.length; i++) {
            if (response.data.images[i].id === details.logo_id) {
              selected = i;
              break;
            }
          }
        }
        this.setState({giftDetailsDialogModal: true, giftDetailsDialogModalDetails: response.data, giftDetailsDialogSelectedId: selected});
      })
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  hideNotification;
  showUsersNotification() {
    if (!this.state.tr)
      this.setState({tr: true});
    else
      clearTimeout(this.hideNotification);
    this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout() {
    this.hideNotification = setTimeout(
      function() {
        this.handleCloseNotification();
      }.bind(this),
      5000
    );
  }

  handleCloseNotification() {
    clearTimeout(this.hideNotification);
    this.setState({tr: false, doneModalMsg: ''});
  }

  handleChildError(title, color) {
    this.setState({ doneModalMsg: title, tr_color: color });
    this.showUsersNotification();
  }

  showAttemptsError() {
    setTimeout(
      function() {
        let error = this.props.auth.attempts_error;
        if (this.props.auth && this.props.auth.attempts_error)
          this.handleChildError(error, 'danger');
        this.props.auth.attempts_error = '';
      }.bind(this),
      100
    )
  }

  handleAutoOpenParish(parishes) {
    if (parishes && parishes.length > 0 && this.state.p && this.props.cards) {
      try {
        const p = parseInt(this.state.p);
        setTimeout( function() {this.setState({p: null});}.bind(this), 100 );
        let selected = parishes.filter(function (parish) {
          return parish.id === p;
        });
        if (selected && selected.length > 0)
          this.handleViewDetailsClick(selected[0]);
      } catch(e) {}
    }
  }

  render() {
    const authService = new AuthService(this.state, this.props);
    if (!authService.getToken())
      return (<div><Redirect to="/auth/login"/></div>);

    this.showAttemptsError();

    const { classes } = this.props;
    let parishes = this._ismounted ? this.props.parishes : null;
    let delay = 500;
    //Uncomment if not needed to request from server
    // if (this.state.show_favourites) {
    //   parishes = parishes.filter(function (parish) {
    //     return parish.favourite === 1;
    //   });
    // }
    //Uncomment if not needed to request from server
    this.handleAutoOpenParish(parishes);

    const topButtonSize = this.props.isGeolocationAvailable ? 4 : 6;
    return (
      <div className={classes.container}>
        <GridContainer>
          {
            this.state.search_term || this.state.find_nearest_parish ? '' :
            <GridItem xs={12} sm={topButtonSize} md={topButtonSize}>
              <Button color={this.state.show_favourites ? 'primary' : "info"} className={classes.updateProfileButton} onClick={this.handleViewFavourites} style={{padding: '10px 8px', width: "100%"}}>
                {this.state.show_favourites ? '' : <i className="fas fa-heart fa-fw" style={{color: "#D75A4A"}}/>} {this.state.show_favourites ? 'Show All Parishes' : 'Favourite Parishes'}
              </Button>
            </GridItem>
          }
          {
            this.state.search_term || this.state.find_nearest_parish
            ? <GridItem xs={12} sm={topButtonSize} md={topButtonSize}>
              <Button color="primary" className={classes.updateProfileButton} onClick={this.handleClearSearch} style={{padding: '10px 8px', width: "100%"}}>
                Show All Parishes
              </Button>
            </GridItem>
            : ''
          }
          {
            this.state.search_term || this.state.find_nearest_parish || this.state.show_favourites ? '' :
            <GridItem xs={12} sm={topButtonSize} md={topButtonSize}>
              <Button color="success" className={classes.updateProfileButton} onClick={this.handleFindNearParishClick} style={{padding: '10px 8px', width: "100%"}}>
                <i className="fas fa-map-marked-alt fa-fw"/> Find Nearest Parish
              </Button>
            </GridItem>
          }
          {
            this.state.show_favourites || this.state.find_nearest_parish ? '' :
            <GridItem xs={12} sm={topButtonSize} md={topButtonSize}>
              <Button color="warning" className={classes.updateProfileButton} onClick={this.handleSearchParishClick} style={{padding: '10px 8px', width: "100%"}}>
                <i className="fas fa-search fa-fw"/> Find a Parish
              </Button>
            </GridItem>
          }
        </GridContainer>
        {
          parishes && parishes.length > 0
            ? <GridContainer>
              {
                parishes.map((prop, key) => {
                  return (
                    <GridItem xs={12} sm={6} md={6} lg={4} key={key}>
                      <ChurchDetailsCard
                        delay={delay + 200}
                        handleAddThumbnailToList={this.handleAddThumbnailToList}
                        handleGetThumbnailFromList={this.handleGetThumbnailFromList}
                        onFavouriteClick={this.handleFavouriteClick}
                        handleViewDetailsClick={this.handleViewDetailsClick}
                        auth={this.props.auth}
                        details={prop}/>
                    </GridItem>
                  );
              })
            }
            </GridContainer>
          : ''
        }
        <SearchDialog
          searchDialogModal={this.state.searchDialogModal}
          searchTitle={'Find a Parish'}
          onClose={() => this.handleClose("searchDialogModal")}
          handleStartSearch={this.handleStartSearch}
          />
        {
          this.state.giftDetailsDialogModal
          ? <GiftDetailsDialog
              handleAddCoverToList={this.handleAddCoverToList}
              handleGetCoverFromList={this.handleGetCoverFromList}
              onFavouriteClick={this.handleFavouriteClick}
              details={this.state.giftDetailsDialogModalDetails}
              selectedIdx={this.state.giftDetailsDialogSelectedId}
              giftDetailsDialogModal={this.state.giftDetailsDialogModal}
              paymentMethods={this.props.cards}
              doFetchCards={this.props.doFetchCards}
              type={"parish"}
              onClose={() => this.handleClose("giftDetailsDialogModal")}
              auth={this.props.auth}
              />
          : ""
        }
        {
          this.state.doneModalMsg
          ? <Snackbar
              place="tr"
              color={this.state.tr_color}
              icon={AddAlert}
              message={this.state.doneModalMsg}
              open={this.state.tr}
              closeNotification={() => {this.handleCloseNotification();}}
              close
          />
          : ''
        }
      </div>
    );
  }
}

Parishes.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    parishes: state.auth.parishes,
    cards: state.auth.cards,
    force_render: Math.random()
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchParishes: (name, lat, long, fav, props) => dispatch(doFetchParishes(name, lat, long, fav, props)),
    doFetchCards: (props) => dispatch(doFetchCards(props)),
  }
}

export default geolocated()(connect(mapStateToProps, mapDispatchToProps)(withStyles(parishesStyle)(Parishes)));
