import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import helpers from "customs/helpers/helpers";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";

class TransactionDetailsDialog extends React.Component {
    render() {
        const { classes } = this.props;
        const details = this.props.transactionDetailsModalMsg;
        return (
            <Dialog
                style={{zIndex: 10000, right: "-35px", left: "-35px"}}
                classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modalSmall}}
                open={this.props.transactionDetailsModal}
                keepMounted
                onClose={()=>this.props.onClose("transactionDetailsModal")}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle style={{paddingBottom:'0px'}} className={classes.modalBody + " " + classes.modalSmallBody}>
                    {'Giving Details ' + (details && details.id ? details.id : '')}
                </DialogTitle>
                <DialogContent id="small-modal-slide-description" className={classes.modalBody + " " + classes.modalSmallBody}>
                    <table style={{minWidth: '300px', width: '100%', textAlign: "left"}}>
                        <tbody>
                            <tr>
                                <td style={{width: '80%'}}>
                                    <b>{details && details.note ? details.note : ""}</b><br/>
                                    {details && details.refunded_at
                                        ? details.refunded_at.split(' ')[0]
                                        : (details && details.created_at ? details.created_at.split(' ')[0] : '')}
                                </td>
                                <td style={{width: '0.5rem', textAlign: 'right', color: details && details.status === 'declined' ? 'red' : (details && details.refunded_at ? 'orange' : 'green')}}>
                                    <b>${helpers.formatMoney((details && details.amount ? details && details.amount : 0) / 100, 2, '.', ',')}<br/></b>
                                    {details && details.refunded_at ? 'refunded' : (details && details.status ? details.status : '')}
                                </td>
                            </tr>   
                            <tr>
                                <td colSpan={2}>
                                    <b>{'Recipient: '}</b>{details && details.recipient && details.recipient.name ? details.recipient.name : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={()=>this.props.onClose("transactionDetailsModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(userProfileStyles)(TransactionDetailsDialog);