import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import helpers from "customs/helpers/helpers";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";

class AlertDialog extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                style={{zIndex: 10001, right: "-35px", left: "-35px"}}
                classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modalSmall}}
                open={this.props.alertDialogModal}
                keepMounted
                onClose={()=>this.props.onClose("alertDialogModal")}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogContent id="small-modal-slide-description" className={classes.modalBody + " " + classes.modalSmallBody}>
                    <h5 style={{ minWidth: '200px', marginBottom: '0px' }}>
                        {helpers.addLineBreaks(this.props.alertDialogModalMsg ? this.props.alertDialogModalMsg : 'An error has occurred, please try again.')}
                    </h5>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={()=>this.props.onClose("alertDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(userProfileStyles)(AlertDialog);