/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios/axiosAuth';
import helpers from "customs/helpers/helpers";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function handleGotoPrivacyPolicy() {
  helpers.showLoading();
  axios.get("/app/endpoints")
    .then(response => {
      helpers.hideLoading();
      let privacy_endpoint = '';
      try {
        let host = window.location.href.split('//');
        let current = host[0] + '//' + host[1].split('/')[0];
        Object.keys(response.data).forEach(function(key) {
            if (response.data[key]['api_endpoint'].indexOf(current) >= 0) {
              privacy_endpoint = response.data[key]['privacy_endpoint'];
            }
        });
      } catch(e) {}
      window.open(privacy_endpoint, "_blank");
    })
    .catch(error => helpers.hideLoading());
};

function handleHelpClick(val) {
  window.location.href = process.env.PUBLIC_URL + '/slides/' + (val === 'Giving Transaction' ? 'giving_transaction.html' : '');
}

function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.right}>
          {fluid ? <span><a className={'privacy-policy'} href="#" onClick={() => handleGotoPrivacyPolicy()}>Privacy Policy</a> <font style={{fontSize: "1.1rem"}}>&bull;</font></span> : ''}  &copy; {1900 + new Date().getYear()}
        </p>
        {
          window.location.href.indexOf('/auth/login') >= 0 && !fluid
          ? <CustomDropdown
              key={"key"}
              data-ref="multi"
              dropPlacement="bottom"
              hoverColor="info"
              buttonText={'Need Help?'}
              buttonProps={{
                  simple: true,
                  style: {float: "left", margin: "0", padding: "15px", fontSize: "14px"}
              }}
              dropdownList={['Set Up of Parish Giving app', 'Giving Transaction']}
              onClick={(val) => handleHelpClick(val)}
            /> : ''
        }
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
