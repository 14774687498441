import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import changeEmailStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import EmailIcon from "@material-ui/icons/Email";
import ConfirmDialog from 'customs/components/ConfirmDialog';

import { connect } from 'react-redux';
import { doFetchUserDetails, doUpdateProfile } from '../../store/actions/authActions';

import AuthService from 'customs/auth/AuthService';
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";

class ChangeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tr: false,
      confirmModal: false,
      confirmModalTitle: '',
      confirm_email: "",
      new_email: ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  componentDidMount () {
    if (!this.props.me) {
      helpers.showLoading();
      this.props.doFetchUserDetails({ ...this.props });
    }
  }

  handleUpdateProfileClick () {
    let cont = true;
    const is_valid_email = validations.validateEmail(this.state.new_email);
    const is_confirm_valid_email = validations.validateEmail(this.state.confirm_email);

    if (!is_valid_email) {
      cont = false;
      this.setState({ "new_email_valid": "error", "new_email_error": validations.emailErrorMsg() });
    } else {
      this.setState({"new_email_valid" : "", "new_email_error": ""});
    }

    if (!is_confirm_valid_email) {
      cont = false;
      this.setState({ "confirm_email_valid": "error", "confirm_email_error": validations.emailErrorMsg() });
    } else {
      this.setState({"confirm_email_valid" : "", "confirm_email_error": ""});
    }

    if (is_valid_email && is_confirm_valid_email && this.state.new_email !== this.state.confirm_email) {
      cont = false;
      this.setState({ "confirm_email_valid": "error", "confirm_email_error": "The email confirmation does not match" });
    }

    if (cont) {
      var x = [];
      x['confirmModal'] = true;
      this.setState({...x, 'confirmModalTitle': 'Change Email'});
    }
  }

  doChangeEmailClick() {
    helpers.showLoading();
    this.handleClose("confirmModal");

    let me = {};
    me['name'] = this.props.me.name;
    me['email'] = this.state.new_email;
    if (this.props.me.phone) me['phone'] = this.props.me.phone;
    if (this.props.me.address1) me['address1'] = this.props.me.address1;
    if (this.props.me.address2) me['address2'] = this.props.me.address2;
    if (this.props.me.city) me['city'] = this.props.me.city;
    if (this.props.me.state) me['state'] = this.props.me.state;
    if (this.props.me.country) me['country'] = this.props.me.country;
    if (this.props.me.postal_code) me['postal_code'] = this.props.me.postal_code;

    this.props.doUpdateProfile(me, { ...this.props });
  }

  render() {
    const authService = new AuthService(this.state, this.props);
    if (!authService.getToken())
      return (<div><Redirect to="/auth/login"/></div>);

    let new_email_valid = this.state.new_email_valid;
    let new_email_error = this.state.new_email_valid === "error" && this.state.new_email
      ? (this.state.new_email_error ? this.state.new_email_error : validations.emailErrorMsg()) : undefined;
    let confirm_email_valid = this.state.confirm_email_valid;
    let confirm_email_error = this.state.confirm_email_valid === "error" && this.state.confirm_email
      ? (this.state.confirm_email_error ? this.state.confirm_email_error : validations.emailErrorMsg()) : undefined;

    const profileUpdated = this.props.auth && this.props.auth.authMessage ? this.props.auth.authMessage : '';
    if (profileUpdated) {
     this.props.auth.authMessage = '';
      setTimeout( function() {
        this.setState({profileUpdated,});
        if (profileUpdated === 'Profile Updated')
          this.setState({new_email: "", confirm_email: ""});
        helpers.hideLoading();
      }.bind(this), 100 );
    }

    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <EmailIcon />
                </CardIcon>
              </CardHeader>
              {
                this.state.profileUpdated
                  ? <CardBody style={{paddingBottom:'0px'}}>
                    <h4 className={classes.textCenter} id="reset_password_status" style={{color: this.state.profileUpdated === 'Profile Updated' ? '#4caf50' : 'red', marginBottom:'0px', paddingBottom:'0px'}}>
                      {this.state.profileUpdated === 'Profile Updated' ? "Email changed" : this.state.profileUpdated}
                    </h4>
                    </CardBody>
                  : ''
              }
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={this.state.email_valid === "success"}
                      error={this.state.email_valid === "error"}
                      labelText="Email address"
                      id="email"
                      helperText={ this.state.email_valid === "error" && this.state.email ? validations.emailErrorMsg()  : undefined }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: this.handleChange,
                        value: this.props.me && this.props.me.email ? this.props.me.email : ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={new_email_valid === "success"}
                      error={new_email_valid === "error"}
                      labelText="New email address"
                      id="new_email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ new_email_error }
                      inputProps={{
                        type: "email",
                        onChange: this.handleChange,
                        value: this.state.new_email
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={confirm_email_valid === "success"}
                      error={confirm_email_valid === "error"}
                      labelText="Confirm email address"
                      id="confirm_email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helperText={ confirm_email_error }
                      inputProps={{
                        type: "email",
                        onChange: this.handleChange,
                        value: this.state.confirm_email
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button color="primary" className={classes.updateProfileButton} onClick={() => this.handleUpdateProfileClick("confirmModal")}>
                  Change Email
                </Button>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <ConfirmDialog
            confirmModal={this.state.confirmModal}
            confirmModalTitle={this.state.confirmModalTitle}
            onClose={() => this.handleClose("confirmModal")} 
            onYesClick={() => this.doChangeEmailClick()} />
        </GridContainer>
      </div>
    );
  }
}

ChangeEmail.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    me: state.auth.me,
    force_render: Math.random(),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchUserDetails: (props) => dispatch(doFetchUserDetails(props)),
    doUpdateProfile: (params, props) => dispatch(doUpdateProfile(params, props))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(changeEmailStyle)(ChangeEmail));
