import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// core components
import Button from "components/CustomButtons/Button.jsx";

class SearchDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleStartSearch = this.handleStartSearch.bind(this);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  handleStartSearch() {
    this.props.handleStartSearch(this.state.search_term);
  }

  render() {
    const { classes } = this.props;
    return (
        <Dialog
            style={{zIndex: 10000, right: "-35px", left: "-35px"}}
            classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal}}
            open={this.props.searchDialogModal}
            keepMounted
            onClose={()=>this.props.onClose("searchDialogModal")}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle style={{paddingBottom:'0px', minWidth: '250px'}} className={classes.modalBodyBody}>
                {this.props.searchTitle}
            </DialogTitle>
            <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
                <CustomInput
                  labelText="Search..."
                  id="search_term"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    onChange: this.handleChange,
                    value: this.state.search_term
                  }}
                />
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button onClick={()=>this.props.onClose("searchDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                    Close
                </Button>
                <Button onClick={this.handleStartSearch} color="success" simple
                    className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                >
                    Search
                </Button>
            </DialogActions>
        </Dialog>
    )
  }
}

export default withStyles(cardsStyle)(SearchDialog);