import Parishes from "views/Dashboard/Parishes.jsx";
import Events from "views/Dashboard/Events.jsx";
import Cards from "views/Dashboard/Cards.jsx";
import GivingHistory from "views/Dashboard/GivingHistory.jsx";
import ErrorPage from "views/Pages/ErrorPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import SendResetEmail from "views/Pages/SendResetEmail.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";

import ChangeEmail from "views/Dashboard/ChangeEmail.jsx";
import ChangePassword from "views/Dashboard/ChangePassword.jsx";
import UpdatePhone from "views/Dashboard/UpdatePhone.jsx";
import UpdateAddress from "views/Dashboard/UpdateAddress.jsx";

import HomeIcon from "@material-ui/icons/Home";
import EventsIcon from "@material-ui/icons/EventNote";
import CardsIcon from "@material-ui/icons/CreditCard";
import GivingHistoryIcon from "@material-ui/icons/CardGiftcard";
import LockIcon from "@material-ui/icons/Lock";

var dashRoutes = [
  {
    path: "/parishes",
    name: "Parishes",
    icon: HomeIcon,
    component: Parishes,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    icon: EventsIcon,
    component: Events,
    layout: "/admin"
  },
  {
    path: "/cards",
    name: "Cards",
    icon: CardsIcon,
    component: Cards,
    layout: "/admin"
  },
  {
    path: "/giving/history",
    name: "Giving History",
    icon: GivingHistoryIcon,
    component: GivingHistory,
    layout: "/admin"
  },
  {
    path: "logout",
    name: "Log Out",
    icon: LockIcon,
    component: GivingHistory,
    layout: "/admin",
    click: "logout"
  },
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/error-page",
    name: "Error Page",
    component: ErrorPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/password/reset",
    name: "Password Reset",
    component: SendResetEmail,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/password/reset",
    name: "Password Reset",
    component: SendResetEmail,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/register",
    name: "Registration Page",
    component: RegisterPage,
    layout: "/auth",
    invisible: true
  },
  
  // Profile
  {
    path: "/change/email",
    name: "Change Email Address",
    component: ChangeEmail,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/change/password",
    name: "Change Password",
    component: ChangePassword,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/update/phone",
    name: "Update Phone",
    component: UpdatePhone,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/update/address",
    name: "Update Address",
    component: UpdateAddress,
    layout: "/admin",
    invisible: true
  },
];
export default dashRoutes;
