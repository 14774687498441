import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import validations from "customs/helpers/validations";
import AlertDialog from "views/Dashboard/Dialogs/AlertDialog.jsx";
import ConfirmDialog from 'customs/components/ConfirmDialog';

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import helpers from "customs/helpers/helpers";

import axios from 'axios/axios';

class ScheduleDetailsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      amount_valid: "",
      note: "",
      payment_method_select: props.details.payment_method_id,
      payment_method_valid: "",
      alertDialogModal: false,
      alertDialogModalMsg: '',
      confirmModal: false,
      confirmModalTitle: '',
      confirmModalMsg: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateScheduleClick = this.handleUpdateScheduleClick.bind(this);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value, [event.target.id + '_valid']: "" });
  }

  validateSubmitTransaction() {
    let cont = true;
    const amount_valid = validations.validateInputHasValue(this.state.amount);
    if (amount_valid && parseFloat(this.state.amount) <= 0) {
      cont = false;
      this.setState({ "amount_valid": "error" });
    } else {
      this.setState({"amount_valid": "",});
    }
    
    const payment_method_valid = validations.validateInputHasValue('' + this.state.payment_method_select);
    if (!payment_method_valid) {
      cont = false;
      this.setState({ "payment_method_valid": "error" });
    } else {
      this.setState({"payment_method_valid": "", "payment_method_add_valid": ""});
    }

    return cont;
  }

  handleUpdateScheduleClick() {
    if (!this.validateSubmitTransaction()) return;

    const fd = new FormData();
    let amount = this.state.amount ? Math.round(this.state.amount * 100) : this.props.details.amount;
    fd.append('payment_method_id', this.state.payment_method_select);
    fd.append('amount', amount);
    if (this.state.note && this.state.note.length > 0) {
      fd.append('note', this.state.note);
    } else if (this.props.details.note && this.props.details.note.length > 0) {
      fd.append('note', this.props.details.note);
    }

    helpers.showLoading();
    axios(this.props).post("/schedule/" + this.props.details.id, fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => {
        let details = this.props.details;
        if (this.state.note && this.state.note.length > 0) {
          details.note = this.state.note;
        } else if (this.props.details.note && this.props.details.note.length > 0) {
          details.note = this.props.details.note;
        }
        details.payment_method_id = this.state.payment_method_select;
        details.amount = amount;
        this.processUpdateSchedule(response, false, details, 'updated');
      })
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processUpdateSchedule(response, close_dialog, schedule, action) {
    this.props.handleUpdateSchedule(schedule, action);
    if (close_dialog) this.props.onClose("scheduleDetailsDialogModal");
  }

  processErrorAxios(error, prop, err_msg) {
    helpers.hideLoading();
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.setState({"alertDialogModal": true, "alertDialogModalMsg": saveDetailsErrors});
  }

  setFrequencyDisplay(recur_period, recur_period_units) {
    return recur_period === 1
      ? recur_period_units.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); }) + 'ly'
      : 'Every ' + recur_period + ' weeks';
  }

  setStatusColor(status) {
    return status === 'paused' ? 'orange' : (status === 'stopped' ? 'red' : (status === 'held' ? 'black' : 'green'));
  }

  handleStatusDelete(type) {
    this.setState({'confirmModal': true, 'confirmModalTitle': 'Recurring Giving', 'confirmModalMsg': 'Do you want to remove Recurring Giving?'});
  }

  handleStatusChange(type) {
    helpers.showLoading();
    axios(this.props).get("/schedule/" + this.props.details.id + '/' + type)
      .then(response => {
        let details = this.props.details;
        details.status = type === 'pause' ? 'paused' : (type === 'delete' ? 'stopped' : "active");
        this.processUpdateSchedule(response, type === "delete", details, type + (type === "restart" ? "ed" : "d"));
      })
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  handleCardChange = event => {
    this.setState({ [event.target.name]: event.target.value, "payment_method_select": event.target.value });
  };

  render() {
    const { classes } = this.props;
    let details = this.props.details;
    return (
        <Dialog
            style={{zIndex: 1200, right: "-35px", left: "-35px"}}
            classes={{root: classes.center + " " + classes.modalRoot, paper: classes.modal + " " + classes.modalSmall}}
            open={this.props.scheduleDetailsDialogModal}
            keepMounted
            onClose={()=>this.props.onClose("scheduleDetailsDialogModal")}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle style={{paddingBottom:'0px', minWidth: '250px'}} className={classes.modalBodyBody}>
                {'Recurring Givings'}
            </DialogTitle>
            <DialogContent id="small-modal-slide-description" className={classes.modalBodyBody}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                <div style={{width: '100%'}}>
                  <table style={{width: '100%', textAlign: "left"}}>
                    <tbody>
                        <tr>
                          <td style={{fontSize: "1.1rem", fontWeight: "bold", width: "90%"}}>
                            {details.recipient && details.recipient.name ? details.recipient.name : ''}
                          </td>
                          <td style={{fontSize: "0.75rem", width: "1rem", textAlign: "right", color: this.setStatusColor(details.status)}}>
                            {details.status ? details.status : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={{fontSize: "1rem"}}>
                            {this.setFrequencyDisplay(details.recur_period, details.recur_period_units)}
                          </td>
                          <td style={{fontSize: "1rem", fontWeight: "bold", width: "1rem", textAlign: "right", color: this.setStatusColor(details.status)}}>
                            ${helpers.formatMoney(details.amount / 100, 2, '.', ',')}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{fontSize: "1rem"}}>
                            {'Next payment due on ' + details.payment_due.split(' ')[0]}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{fontSize: "1rem"}}>
                            {'Paying with: ' + (details.payment_method.alias ? details.payment_method.alias : details.payment_method.pan)}
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{textAlign: "center", marginTop: "1rem"}}>
                  <span style={{margin: "0px 5px"}}>
                    <Button
                      disabled={details.status !== "active"}
                      color="primary"
                      round
                      className={classes.marginRight}
                      onClick={() => this.handleStatusChange("pause")}
                    >
                      Pause
                    </Button>
                  </span>
                  <span style={{margin: "0px 5px"}}>
                    <Button
                      disabled={details.status !== "paused"}
                      color="primary"
                      round
                      className={classes.marginRight}
                      onClick={() => this.handleStatusChange("restart")}
                    >
                      Restart
                    </Button>
                  </span>
                  <span style={{margin: "0px 5px"}}>
                    <Button
                      color="primary"
                      round
                      className={classes.marginRight}
                      onClick={() => this.handleStatusDelete("delete")}
                    >
                      Delete
                    </Button>
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    success={this.state.amount_valid === "success"}
                    error={this.state.amount_valid === "error"}
                    labelText="New Amount (optional)"
                    id="amount"
                    helperText={ this.state.amount_valid === "error" && this.state.amount ? "Please enter an amount greater than 0" : undefined }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: this.handleChange,
                      value: this.state.amount
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="payment-method"
                    className={classes.selectLabel}
                  >
                    Choose a Card
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.payment_method_select}
                    onChange={this.handleCardChange}
                    inputProps={{
                      name: "payment_method_select",
                      id: "payment-method"
                    }}
                  >
                    <MenuItem key={999}
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose a Card
                    </MenuItem>
                    {
                      this.props.paymentMethods.map((prop, key) => {
                        return <MenuItem key={key}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={prop.id}
                        >
                          {(prop.alias ? (prop.alias + ' ') : '') + '(' + prop.pan + ')'}
                        </MenuItem>
                      })
                    }
                  </Select>
                  {
                    this.state.payment_method_valid !== "error" ? '' : <label style={{color: '#f44336', fontWeight: "300", fontSize: "0.75rem"}}>{'Please select a card'}</label>
                  }
                  {
                    this.state.payment_method_add_valid !== "error" ? '' : <label style={{color: '#f44336', fontWeight: "300", fontSize: "0.75rem"}}>{'Please add a card'}</label>
                  }
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Personal Giving Note (Optional)"
                    id="note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      onChange: this.handleChange,
                      value: this.state.note
                    }}
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button onClick={()=>this.props.onClose("scheduleDetailsDialogModal")} color="transparent" className={classes.modalSmallFooterFirstButton}>
                    Close
                </Button>
                <Button onClick={this.handleUpdateScheduleClick} color="success" simple
                    className={classes.modalSmallFooterFirstButton + " " + classes.modalSmallFooterSecondButton}
                >
                    Change
                </Button>
            </DialogActions>
            <ConfirmDialog
              confirmModal={this.state.confirmModal}
              confirmModalTitle={this.state.confirmModalTitle}
              onClose={() => this.handleClose("confirmModal")} 
              confirmModalMsg={this.state.confirmModalMsg}
              onYesClick={() => this.handleStatusChange('delete')} />
            <AlertDialog
              alertDialogModal={this.state.alertDialogModal}
              alertDialogModalMsg={this.state.alertDialogModalMsg}
              onClose={() => this.handleClose("alertDialogModal")}  />
        </Dialog>
    )
  }
}

export default withStyles(cardsStyle)(ScheduleDetailsDialog);