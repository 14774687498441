import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import moment from 'moment';
import cc_type from 'credit-card-type';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class CardDetailsCard extends React.Component {
    constructor(props) {
        super(props);
        this.onToggleSwitch = this.onToggleSwitch.bind(this);
        this.handleViewCard = this.handleViewCard.bind(this);
    }

    onToggleSwitch(details, event) {
        event.preventDefault();
        if (details) this.props.onToggleSwitch(details.id, details.alias, !details.default);
    }

    handleViewCard(details) {
        if (details) this.props.onViewCardClick(details);
    }

    getCardBGColor(type) {
        if (type && type[0] && type[0]['type']) {
            switch(type[0]['type']) {
                case "visa":
                    return 'linear-gradient(140deg, #1064AB 50%, #FCB70A)';
                case "mastercard":
                    return 'linear-gradient(140deg, #EB001B, #F79E1B)';
                case "amex":
                case "american-express":
                    return 'linear-gradient(140deg, #5F9B82, #93C1AC)';
                default:
                    return "url(" + process.env.PUBLIC_URL + "/images/cc-bg.jpg)";
            }
        } else
            return "url(" + process.env.PUBLIC_URL + "/images/cc-bg.jpg)";
    }

    render() {
        const details = this.props.details;
        const expiry = (details && details.expiry_month ? details.expiry_month.toString().padStart(2, "0") : '') + '/' + (details && details.expiry_year ? details.expiry_year.toString().substr(-2) : '');
            // moment(details && details.expires ? details.expires : '');
        const { classes } = this.props;
        let type = cc_type(details && details.pan ? details.pan : '411111');
        return <Card
            style={{
                minWidth: this.props.doShowToggle ? '100px' : '300px',
                marginTop: '20px', marginBottom: '5px', padding: "20px 10px",
                backgroundImage: this.getCardBGColor(type)
            }}>
            <CardBody style={{padding: '10px 5px'}}>
                <table style={{width: '100%', textAlign: "left", color: "#eeeeee", paddingTop: this.props.doShowToggle ? "" : "1rem", paddingBottom: this.props.doShowToggle ? "" : "1rem"}}>
                    <tbody>
                        <tr onClick={() => this.handleViewCard(details)} style={{cursor: "pointer"}}>
                            <td colSpan={2} style={{fontSize: "25px", fontWeight: "bold", textAlign: "center"}}>
                                {details && details.pan ? details.pan : ''}
                            </td>
                        </tr>
                        <tr onClick={() => this.handleViewCard(details)} style={{cursor: "pointer"}}>
                            <td style={{width: '80%'}}>
                                {details && details.alias ? details.alias : ''}<br/>
                                {type && type[0] && type[0]['niceType'] ?  type[0]['niceType'] : ''}
                            </td>
                            <td style={{width: '20%'}}>
                                {'Expires'}<br/>
                                {expiry/*.format('MM/YY')*/}
                            </td>
                        </tr>
                        {
                            this.props.doShowToggle
                            ? <tr style={{ display: this.props.doShowToggle ? '': 'none' }}>
                                <td colSpan={2}>
                                <FormControlLabel
                                    style={{color: "white"}}
                                    control={
                                    <Switch
                                        checked={details && details.default && details.default === true}
                                        onChange={(event) => this.onToggleSwitch(details, event)}
                                        value="checkedA"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                    }
                                    label="Default"
                                />
                                </td>
                            </tr>
                            : null
                        }
                    </tbody>
                </table>
            </CardBody>
        </Card>
    }
}

export default CardDetailsCard;