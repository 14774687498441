import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import cardsStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardDetailsCard from "views/Components/CardDetailsCard.jsx";
import ViewCardDialog from "views/Dashboard/Dialogs/ViewCardDialog.jsx";
import CardCreateDialog from "views/Dashboard/Dialogs/CardCreateDialog.jsx";
import helpers from "customs/helpers/helpers";
import AlertDialog from "views/Dashboard/Dialogs/AlertDialog.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { doFetchCards } from '../../store/actions/parishActions';

import { connect } from 'react-redux';
import AuthService from 'customs/auth/AuthService';
import axios from 'axios/axios';

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},
      alertDialogModal: false,
      alertDialogModalMsg: '',
      viewCardDialogModal: false,
      viewCardDialogModalMsg: {},
      cardCreateDialogModal: false,
      access_token: null,
      temporary_token_url: "",
      tr: false,
      tr_color: "success",
      doneModalMsg: ''
    };

    this.handleUpdateCard = this.handleUpdateCard.bind(this);
    this.handleAddCard = this.handleAddCard.bind(this);
    this.handleViewCard = this.handleViewCard.bind(this);
    this.processUpdateCard = this.processUpdateCard.bind(this);
    this.handleChildError = this.handleChildError.bind(this);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  componentDidMount () {
    helpers.showLoading();
    this.props.doFetchCards({ ...this.props });
  }

  handleViewCard(details) {
    helpers.showLoading();
    axios(this.props).get("/payment_method/" + details.id).then(response => this.processViewCard(response)).catch(error => this.processErrorAxios(error, null, "An error has occurred while retrieving your transaction, please try again."));
  }

  processViewCard(response) {
    helpers.hideLoading();
    this.setState({"viewCardDialogModal": true, "viewCardDialogModalMsg": response.data});
  }

  handleUpdateCard(id, alias, is_default) {
    helpers.showLoading();
    const fd = new FormData();
    fd.append('alias', alias ? alias : "");
    fd.append('default', is_default ? 1 : 0);
    axios(this.props).post("/payment_method/" + id, fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processUpdateCard(response, id, 'updated'))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processUpdateCard(response, id, action) {
    helpers.showLoading();
    this.props.doFetchCards({ ...this.props });

    this.setState({ doneModalMsg: 'Card ' + action, tr_color: "success" });
    if (this.state.viewCardDialogModal) {
      let cardDetails = this.state.viewCardDialogModalMsg;
      cardDetails.alias = response.data.alias;
      cardDetails.default = response.data.default;
      this.setState({"viewCardDialogModal": true, "viewCardDialogModalMsg": cardDetails});
    }
    this.showUsersNotification();
  }

  handleAddCard(temporary_token, alias, is_default) {
    const fd = new FormData();
    fd.append('temporary_token', temporary_token);
    fd.append('alias', alias);
    fd.append('default', is_default ? 1 : 0);
    axios(this.props).post("/payment_method/create", fd, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processAddCard(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processAddCard(response) {
    helpers.showLoading();
    this.setState({"cardCreateDialogModal": false});
    this.props.doFetchCards({ ...this.props });

    this.setState({ doneModalMsg: 'Card added', tr_color: "success" });
    this.showUsersNotification();
  }

  processErrorAxios(error, prop, err_msg) {
    helpers.hideLoading();
    let default_err = helpers.getErrorMessage(error);
    let saveDetailsErrors = default_err === false ? err_msg : default_err;
    this.handleChildError(saveDetailsErrors, 'danger');
  }

  handleShowAddCard() {
    if (this.state.access_token && this.state.temporary_token_url) return this.showAddCard();
    this.handleFetchAccessToken();
  }

  handleFetchAccessToken() {
    helpers.showLoading();
    axios(this.props).post("/payment_method/access_token", {}, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => this.processFetchAccessToken(response))
      .catch(error => this.processErrorAxios(error, null, "An error has occurred, please try again."));
  }

  processFetchAccessToken(response) {
    helpers.hideLoading();
    this.setState({access_token: response.data.access_token, temporary_token_url: response.data.submit_url});
    this.showAddCard();
  }

  showAddCard() {
    this.setState({"cardCreateDialogModal": true});
  }

  hideNotification;
  showUsersNotification() {
    if (!this.state.tr)
      this.setState({tr: true});
    else
      clearTimeout(this.hideNotification);
    this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout() {
    this.hideNotification = setTimeout(
      function() {
        this.handleCloseNotification();
      }.bind(this),
      5000
    );
  }

  handleCloseNotification() {
    clearTimeout(this.hideNotification);
    this.setState({tr: false, doneModalMsg: ''});
  }

  handleChildError(title, color) {
    this.setState({ doneModalMsg: title, tr_color: color });
    this.showUsersNotification();
  }

  showAttemptsError() {
    setTimeout(
      function() {
        let error = this.props.auth.attempts_error;
        if (this.props.auth && this.props.auth.attempts_error)
          this.handleChildError(error, 'danger');
        this.props.auth.attempts_error = '';
      }.bind(this),
      100
    )
  }

  render() {
    const authService = new AuthService(this.state, this.props);
    if (!authService.getToken())
      return (<div><Redirect to="/auth/login"/></div>);

    this.showAttemptsError();
      
    const btnStyle = {background: "transparent", padding: "5px 10px", outline: "0", marginTop: "10px", border: "0", display: "inline-flex", alignItems: "center"};
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {
          this.props.cards && this.props.cards.length > 0
            ? <GridContainer>
              {
                this.props.cards.map((prop, key) => {
                  return (
                    <GridItem xs={12} sm={6} md={6} lg={4} key={key}>
                      <CardDetailsCard
                        details={prop}
                        onToggleSwitch={this.handleUpdateCard}
                        onViewCardClick={this.handleViewCard}
                        doShowToggle={true}
                        {...this.props}/>
                    </GridItem>
                  );
              })
            }
            </GridContainer>
          : ''
        }
        <GridContainer justify="center">
          <button style={btnStyle} onClick={() => this.handleShowAddCard()}>
            <i className="fa fa-plus-circle" style={{fontSize: '25px', margin: "0px 03px"}}/> Add Card
          </button>
        </GridContainer>
        <ViewCardDialog
          details={this.state.viewCardDialogModalMsg}
          viewCardDialogModal={this.state.viewCardDialogModal}
          handleUpdateCard={this.handleUpdateCard}
          processUpdateCard={this.processUpdateCard}
          handleChildError={this.handleChildError}
          {...this.props}
          onClose={() => this.handleClose("viewCardDialogModal")}  />
        {
          this.state.cardCreateDialogModal
          ? <CardCreateDialog
            details={this.state.cardCreateDialogModal}
            cardCreateDialogModal={this.state.cardCreateDialogModal}
            access_token={this.state.access_token}
            temporary_token_url={this.state.temporary_token_url}
            handleAddCard={this.handleAddCard}
            {...this.props}
            onClose={() => this.handleClose("cardCreateDialogModal")}  />
          : ''
        }
        <AlertDialog
          alertDialogModal={this.state.alertDialogModal}
          alertDialogModalMsg={this.state.alertDialogModalMsg}
          onClose={() => this.handleClose("alertDialogModal")}  />
        {
          this.state.doneModalMsg
          ? <Snackbar
              place="tr"
              color={this.state.tr_color}
              icon={AddAlert}
              message={this.state.doneModalMsg}
              open={this.state.tr}
              closeNotification={() => {this.handleCloseNotification();}}
              close
          />
          : ''
        }
      </div>
    );
  }
}

Cards.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  helpers.hideLoading();
  return {
    auth: state.auth.data,
    cards: state.auth.cards,
    force_render: Math.random()
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchCards: (props) => dispatch(doFetchCards(props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(cardsStyle)(Cards));
