import React from "react";
import axios from 'axios/axios';

class ImageContainer extends React.Component {
    constructor(props) {
        super(props);

        let max_y = 480;
        let max_x = 720;
        try {
            let ratio = 720 / 480;
            max_y = parseInt(30 * parseFloat(getComputedStyle(document.documentElement).fontSize));
            max_x = parseInt(max_y * ratio);
        } catch (e) {
            max_y = 480;
            max_x = 720;
        }
        this.state = {
          logo: null,
          max_y: max_y,
          max_x: max_x,
          width: window && window.innerWidth ? window.innerWidth : 0,
          height: window && window.innerHeight ? window.innerHeight : 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentWillUnmount() {
        this._ismounted = false;

        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        this._ismounted = true;
        if (this.props.image_id) this.loadImage(this.props.image_id);

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    loadImage(id) {
        let uri = this.props.handleGetCoverFromList(id);
        if (uri) {
            this.setState({logo: uri});
        } else {
            setTimeout(() => {
                axios(this.props).get("image/" + this.props.type + "/" + id + "?max_x=" + this.state.max_x + "&max_y=" + this.state.max_y, {responseType: 'arraybuffer',})
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        if (this._ismounted) {
                            this.setState({logo: url});
                            this.props.handleAddCoverToList(id, url);
                        }
                    })
                    .catch((error) => console.log(error));
            }, this.props.delay ? this.props.delay : 200);
        }
    }

    render() {
        let style = this.state.height > this.state.width
            ? {borderRadius: '10px', maxWidth: parseInt(this.state.max_x / 2) + 'px', height: "auto"}
            : {borderRadius: '10px', height: parseInt(this.state.max_y / 2) + 'px', width: "auto"};
        return <div>
            <img src={this.state.logo ? this.state.logo : (process.env.PUBLIC_URL + '/images/cover.png')} style={style} alt={this.props.alt_text} />
        </div>
    }
}

export default ImageContainer;